import CryptoJS from "crypto-js";

const SECRET_KEY = "akosmdtechpatientportalapsfghjkf"; // 32-byte key (string)
const IV = "1dfdb8cce5e9b76f25d3396b0b550da7"; // 16-byte IV (string)

export interface EncryptedData {
  encryptedData: string;
  IV: string;
}

// Encrypt the payload (object or query parameters)
export const encryptData = (plaintext: object | string): EncryptedData => {
  const keyWords = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  const ivWords = CryptoJS.enc.Hex.parse(IV);

  const encrypted = CryptoJS.AES.encrypt(
    typeof plaintext === "string" ? plaintext : JSON.stringify(plaintext),
    keyWords,
    {
      iv: ivWords,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return {
    encryptedData: encrypted.ciphertext.toString(CryptoJS.enc.Hex),
    IV: IV,
  };
};

// Decrypt the encrypted data
export const decryptData = (encryptedData: string, iv: string): object | string => {
  const keyWords = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  const ivWords = CryptoJS.enc.Hex.parse(iv);

  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Hex.parse(encryptedData),
  });

  const decrypted = CryptoJS.AES.decrypt(cipherParams, keyWords, {
    iv: ivWords,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  try {
    return JSON.parse(decryptedText); // Return JSON object
  } catch {
    return decryptedText; // Return plain string if JSON parsing fails
  }
};
