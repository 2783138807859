import { Box, Modal } from '@mui/material'
import React from 'react'
import { CrossIcon } from '../../utils/functions/Icons'
import BtnLoader from '../common/BtnLoader'
interface propTypes {
    openLabChange: boolean,
    CloseLabChange: () => void,
    confirmLabChange: () => void,
    btnLoading?: boolean,
}
const ConfirmChangeLab: React.FC<propTypes> = ({ openLabChange, CloseLabChange, confirmLabChange, btnLoading }) => {
    return (
        <Modal
            open={openLabChange}
            onClose={CloseLabChange}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 500 }} className={"ModalBoxStyle"}>
                <div className="modalHeader">
                    <h3>Changing the lab will automatically remove the previously selected tests and packages.</h3>
                    <CrossIcon onClick={CloseLabChange} />
                </div>
                <div className='ModalContentSection'>
                    <div className='confirmationBtns'>
                        <button className='outLineBtn' onClick={CloseLabChange}>No</button>
                        <button className='fillButton' disabled={btnLoading} onClick={confirmLabChange}>{btnLoading && <BtnLoader />} Yes</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ConfirmChangeLab