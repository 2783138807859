import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { FormGroup, InputAdornment, MenuItem, Select, TextField } from '@mui/material'
import { BackIcon, DocumentIcon, SearchIcon } from '../../utils/functions/Icons'
import styles from "../../assets/styles/teams.module.css"
import { useLocation, useNavigate } from 'react-router-dom'
import prescriptionImg from "../../assets/images/common/prescription.png"
import DocumentViewModal from '../../components/savedDocuments/DocumentViewModal'
import NoDocuments from '../../components/common/NoDocuments'
import { getFilesApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import Loader from '../../components/common/Loader'
import { Images } from '../../utils/functions/Images'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../utils/store'
import { handleViewDocument } from '../../utils/store/common/CommonSlice'

interface filesDataTypes {
    fileName: string;
    fileType: string;
    fileUrl: string;
    id: number
}
const SavedDocumentView = () => {
    const [viewDocument, setViewDocument] = useState(false)
    const [filesData, setFilesData] = useState<filesDataTypes[] | []>([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState<string | number>("0");
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const fetchFilesData = async (data: { search: string, id: number, timeFilter: string | number }) => {
        try {
            setLoading(true)
            const result = await getFilesApi(data);
            if (result?.success) {
                setLoading(false)
                const showRes = result?.files?.filter((item: filesDataTypes) => item?.fileType === "1")
                setFilesData(showRes);
            }
            else {
                setLoading(false)
                setFilesData([]);
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (location.state) {
            if (searchValue) {
                const cleanFunction = setTimeout(() => {
                    fetchFilesData({ search: searchValue || '', id: location.state, timeFilter: year });
                }, 1000)
                return () => clearTimeout(cleanFunction)
            }
            else {
                fetchFilesData({ search: searchValue || '', id: location.state, timeFilter: year });
            }
        }
    }, [searchValue, location.pathname, year]);

    const handleViewPrescription = (item?: filesDataTypes) => {
        dispatch(handleViewDocument({
            prescriptionURL: item?.fileUrl ? item?.fileUrl : '',
            fileName: item?.fileName
        }))
    }

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4'><BackIcon onClick={() => navigate(-1)} />Prescriptions</h2>
                </div>
                <div className={`${styles.SearchBoxMain} flex justify-between flex-col md:flex-row items-center w-full max-w-full gap-y-6`}>
                    <FormGroup className="customFormGroup md:w-1/2 w-full">
                        <TextField
                            className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            placeholder='Search by folder name or document'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="customFormGroup md:w-36 w-full">
                        <Select
                            className={`custumTextField profileInput`}
                            placeholder="Date"
                            defaultValue={"0"}
                            value={year}
                            onChange={(e) => setYear(e.target?.value)}
                        >
                            <MenuItem selected value="0">All</MenuItem>
                            <MenuItem value="1">Last Month</MenuItem>
                            <MenuItem value="2">6 Months</MenuItem>
                            <MenuItem value="3">1 Year</MenuItem>
                        </Select>
                    </FormGroup>
                </div>
                {
                    loading ? <Loader />
                        :
                        <div className={styles.AllDocuments}>
                            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 md:gap-6 gap-y-6">
                                {filesData?.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.DocumentInnerMain} onClick={() => handleViewPrescription(item)}>
                                            <div className={styles.VDocBoxMain}>
                                                <div className={styles.VDocBoxHead}>
                                                    <p>{item?.fileName}</p>
                                                </div>
                                                <div className={styles.DocFileBox}>
                                                    <img src={Images.filesIcon} alt="prescription image" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                }
                {/* <NoDocuments /> */}
            </div>
            <DocumentViewModal />
        </CustomContainer>
    )
}

export default SavedDocumentView