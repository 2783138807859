import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDoctorsApi, getMyCareTeamApi } from "../../api/services";
import { Moment } from "moment";

interface TeamTypes {
  CareTeamData: CareTeamTypes[];
  MyCareTeamData: CareTeamTypes[];
  loading: boolean;
  SelectedDoctor: CareTeamTypes[];
  isModalOpenAppointment: boolean;
  particularData: CareTeamTypes | null;
}
export const CareTeamApiReducer = createAsyncThunk<
  TeamTypes["CareTeamData"],
  { id: number; search?: string }
>("cart/CareTeamApiReducer", async (data, thunkAPI) => {
  try {
    const result = await getDoctorsApi(data);
    if (result?.success) {
      return result?.doctors as TeamTypes["CareTeamData"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const MyCareTeamApiReducer = createAsyncThunk<
  TeamTypes["MyCareTeamData"],
  { search?: string }
>("cart/MyCareTeamApiReducer", async (data, thunkAPI) => {
  try {
    const result = await getMyCareTeamApi(data);
    if (result?.success) {
      return result?.result as TeamTypes["MyCareTeamData"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: TeamTypes = {
  CareTeamData: [],
  loading: false,
  MyCareTeamData: [],
  SelectedDoctor: [],
  isModalOpenAppointment: false,
  particularData: null,
};

const CareTeamSlice = createSlice({
  name: "careteam",
  initialState,
  reducers: {
    toggleDoctorSelection: (state, action: PayloadAction<number>) => {
      const doctor = state.CareTeamData.find(
        (doctor) => doctor.id === action.payload
      );
      if (doctor) {
        const selectedCount = state.CareTeamData.filter(
          (doc) => doc.isChecked
        ).length;
        if (doctor.isChecked || selectedCount < 3) {
          doctor.isChecked = !doctor.isChecked;
          state.SelectedDoctor = state.CareTeamData.filter(
            (doc) => doc.isChecked
          );
        }
      }
    },
    resetDoctorSelection: (state) => {
      state.CareTeamData.forEach((doctor) => {
        doctor.isChecked = false;
      });
      state.SelectedDoctor = [];
    },
    openBookAppointment: (
      state,
      action: PayloadAction<TeamTypes["particularData"]>
    ) => {
      state.isModalOpenAppointment = true;
      state.particularData = action.payload ?? null;
    },
    closeBookAppointment: (state) => {
      state.isModalOpenAppointment = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CareTeamApiReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      CareTeamApiReducer.fulfilled,
      (state, action: PayloadAction<TeamTypes["CareTeamData"] | undefined>) => {
        state.CareTeamData = action.payload ?? [];
        state.loading = false;
      }
    );
    builder.addCase(CareTeamApiReducer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(MyCareTeamApiReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      MyCareTeamApiReducer.fulfilled,
      (
        state,
        action: PayloadAction<TeamTypes["MyCareTeamData"] | undefined>
      ) => {
        state.MyCareTeamData = action.payload ?? [];
        state.loading = false;
      }
    );
    builder.addCase(MyCareTeamApiReducer.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  toggleDoctorSelection,
  resetDoctorSelection,
  openBookAppointment,
  closeBookAppointment,
} = CareTeamSlice.actions;
export default CareTeamSlice;
