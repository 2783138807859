import styles from "../../assets/styles/chat.module.css"
const NoChat = () => {
    return (
        <div className={styles.NoChat}>
            <h3>Select to Chat</h3>
            <p>Here you can contact your doctor or care navigator</p>
        </div>
    )
}

export default NoChat