import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBookedLabTests, getCartDetailsApi, getLabCitiesApi, getLabOrderApi, GetPackageAndTestApi, getTestandPackageByLabApi } from "../../api/services";

interface StateTypes {
  PackagesAndTestData: PackagesAndTestData;
  loading: boolean;
  CartItem: CartItem[] | [];
  LabCityData: CityTypes[] | [];
  PackagesAndTestDataByLab: PackagesAndTestData,
  LabSummaryData: OrderSummary | null;
  BookedLabData: BookedLabTestData | null;
  disableLabChange: boolean;
}

export const PackageAndTestApiReducer = createAsyncThunk<
  PackagesAndTestData,
  { companyId: number, search: string }
>("labtest/PackageAndTestApiReducer", async (data, thunkAPI) => {
  try {
    const result = await GetPackageAndTestApi(data);
    if (result?.success) {
      return result?.result as PackagesAndTestData;
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const PackageAndTestByLabApiReducer = createAsyncThunk<any,
  { labId: number | null, cartId: number, companyId: number | undefined }
>("labtest/PackageAndTestByLabApiReducer", async (data, thunkAPI) => {
  try {
    const result = await getTestandPackageByLabApi(data);
    if (result?.success) {
      return {
        categoryPackages: result?.packages,
        categoryTests: result?.tests
      };
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const cartDetailsReducer = createAsyncThunk<
  StateTypes['CartItem'],
  { patientId: number | undefined, labId?: number, cartId?: number }
>("labtest/cartDetailsReducer", async (data, thunkAPI) => {
  try {
    const result = await getCartDetailsApi(data);
    if (result?.success) {
      return result?.cartDetails as StateTypes['CartItem'];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getLabCityReducer = createAsyncThunk<
  StateTypes['LabCityData']
>("labtest/getLabCityReducer", async (data, thunkAPI) => {
  try {
    const result = await getLabCitiesApi();
    if (result?.success) {
      return result?.cities as StateTypes['LabCityData'];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getLabSummaryReducer = createAsyncThunk<
  StateTypes['LabSummaryData'],
  { id: number }
>("labtest/getLabSummaryReducer", async (data, thunkAPI) => {
  try {
    const result = await getLabOrderApi(data);
    if (result?.success) {
      return result?.labOrders as StateTypes['LabSummaryData'];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getBookedLabTestReducer = createAsyncThunk<
  StateTypes['BookedLabData'],
  { id: number }
>("labtest/getBookedLabTestReducer", async (data, thunkAPI) => {
  try {
    const result = await getBookedLabTests(data);
    if (result?.success) {
      return result?.data as StateTypes['BookedLabData'];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: StateTypes = {
  PackagesAndTestData: {
    categoryPackages: {},
    categoryTests: {}
  },
  loading: false,
  CartItem: [],
  LabCityData: [],
  PackagesAndTestDataByLab: {
    categoryPackages: {},
    categoryTests: {}
  },
  LabSummaryData: null,
  BookedLabData: null,
  disableLabChange: false
};

const LabTestSlice = createSlice({
  name: "labtest",
  initialState,
  reducers: {
    setLoading: ((state, action: PayloadAction<StateTypes['loading']>) => {
      state.loading = action?.payload ?? false
    }),
    setDisableLabChange: ((state, action: PayloadAction<StateTypes['disableLabChange']>) => {
      state.disableLabChange = action?.payload ?? false
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(PackageAndTestApiReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      PackageAndTestApiReducer.fulfilled,
      (state, action: PayloadAction<PackagesAndTestData | undefined>) => {
        state.PackagesAndTestData = action.payload ?? { categoryPackages: {}, categoryTests: {} };
        state.loading = false;
      }
    );
    builder.addCase(PackageAndTestApiReducer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(PackageAndTestByLabApiReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      PackageAndTestByLabApiReducer.fulfilled,
      (state, action: PayloadAction<PackagesAndTestData | undefined>) => {
        state.PackagesAndTestDataByLab = action.payload ?? { categoryPackages: {}, categoryTests: {} };
        state.loading = false;
      }
    );
    builder.addCase(PackageAndTestByLabApiReducer.rejected, (state) => {
      state.loading = false;
      state.PackagesAndTestDataByLab = { categoryPackages: {}, categoryTests: {} }
    });
    builder.addCase(cartDetailsReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      cartDetailsReducer.fulfilled,
      (state, action: PayloadAction<StateTypes['CartItem'] | undefined>) => {
        state.CartItem = action.payload ?? [];
        state.loading = false;
      }
    );
    builder.addCase(cartDetailsReducer.rejected, (state) => {
      state.loading = false;
      state.CartItem = [];
    });
    builder.addCase(
      getLabCityReducer.fulfilled,
      (state, action: PayloadAction<StateTypes['LabCityData'] | undefined>) => {
        state.LabCityData = action.payload ?? [];
      }
    );
    builder.addCase(
      getLabSummaryReducer.pending,
      (state) => {
        state.loading = true
      }
    );
    builder.addCase(
      getLabSummaryReducer.fulfilled,
      (state, action: PayloadAction<StateTypes['LabSummaryData'] | undefined>) => {
        state.loading = false
        if(action.payload){
          state.LabSummaryData = (action.payload as any)[0] ?? null;
        }
      }
    );
    builder.addCase(
      getLabSummaryReducer.rejected,
      (state) => {
        state.loading = false
        state.LabSummaryData = null;
      }
    );
    builder.addCase(
      getBookedLabTestReducer.pending,
      (state) => {
        state.loading = true
      }
    );
    builder.addCase(
      getBookedLabTestReducer.fulfilled,
      (state, action: PayloadAction<StateTypes['BookedLabData'] | undefined>) => {
        state.loading = false;
        state.BookedLabData = action.payload ?? null;
      }
    );
    builder.addCase(
      getBookedLabTestReducer.rejected,
      (state) => {
        state.BookedLabData = null;
        state.loading = false
      }
    );
  },
});
export const { setLoading, setDisableLabChange } = LabTestSlice.actions;
export default LabTestSlice;