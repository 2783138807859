import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import userIcon from "../../assets/images/team/1.png"
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import { SearchIcon } from '../../utils/functions/Icons';
import ConfirmModal from '../common/ConfirmModal';
import { FC, useEffect, useState } from 'react';
import NoAppointment from '../common/NoAppointment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { fetchUpcomingAppReducer } from '../../utils/store/appointment/AppointmentSlice';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { cancelAppointmentApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import { Images } from '../../utils/functions/Images';
interface Props {
    handleJoinNow: (item: appointmentTypes) => void
}
const UpComing: FC<Props> = ({ handleJoinNow }) => {
    const [searchValue, setSearchValue] = useState('')
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const { loading, UpcomingAppointment, } = useSelector((state: RootState) => state.appointment)
    const [particularData, setParticularData] = useState<appointmentTypes | null>(null)
    const { isCareNavigator } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch<AppDispatch>()

    const handleCancelAppointment = (item: appointmentTypes) => {
        setParticularData(item);
        setOpenConfirmModal(true)
    }
    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(fetchUpcomingAppReducer({ search: searchValue || '', id: 1 }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(fetchUpcomingAppReducer({ search: searchValue || '', id: 1 }));
        }
    }, [searchValue]);

    const confirmSubmit = async () => {
        try {
            setBtnLoading(true)
            const result = await cancelAppointmentApi({ appointmentId: particularData?.appointmentId });
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                dispatch(fetchUpcomingAppReducer({ search: searchValue || '', id: 1 }));
                CloseConfirmModal();
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    return (
        <div>
            <div className={'md:w-1/2 w-full mb-6'}>
                <FormGroup className="customFormGroup">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search by doctor or specialising in'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell>Doctor Name</TableCell>
                                    <TableCell align="right">Experience</TableCell>
                                    <TableCell align="right">Specialisation</TableCell>
                                    <TableCell align="right">Date & Time</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                UpcomingAppointment?.length > 0 ?
                                    <TableBody>
                                        {
                                            UpcomingAppointment?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.doctorName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {`${item?.experience !== '1' ? `${item?.experience} years` : `${item?.experience} year`}`}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.speciality}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.dateTime}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button className={`${styles.StatusBtn} ${item?.isConfirmed === 0 ? styles.StatusBtnPending : ''}`}>{item?.isConfirmed === 1 ? 'Confirmed' : 'Pending'}</button>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <p className={styles.cancelAppointment} onClick={() => handleCancelAppointment(item)}>Cancel appointment</p>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button disabled={(item?.isConfirmed === 1 && item?.joinNow === 1 && !isCareNavigator) ? false : true} className={`outLineBtn ${styles.downloadPresBtn} joinNowForDisable`} onClick={() => handleJoinNow(item)}>Join Now</button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }

            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                btnLoading={btnLoading}
                title='Are you sure, you want to cancel your appointment?'
            />
        </div>
    );
}
export default UpComing