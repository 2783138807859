import React, { useEffect, useState } from 'react'
import styles from "../../assets/styles/labtest.module.css"
import CustomContainer from '../../components/common/CustomContainer'
import { useLocation, useNavigate } from 'react-router-dom'
import { BackIcon, CheckedIcon } from '../../utils/functions/Icons'
import { Images } from '../../utils/functions/Images'
import { DateCalendar } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { cartDetailsReducer, getLabCityReducer, PackageAndTestByLabApiReducer, setLoading } from '../../utils/store/labtest/LabTestSlice'
import Loader from '../../components/common/Loader'
import NoDataFound from '../../components/common/NoDataFound'
import { fixLabOnCartApi, getLabBranchesApi, getLabHomeAddressApi, getLabListByCity, labDetailsByCartIdForChangeLabApi, labOrderApi, removeAllCartApi, removeCartItemApi, updateLabHomeAddressApi } from '../../utils/api/services'
import ConfirmModal from '../../components/common/ConfirmModal'
import { toast } from 'react-toastify'
import { FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from '@mui/material'
import AddMoreLabTest from '../../components/labtest/AddMoreLabTest'
import ConfirmChangeLab from '../../components/labtest/ConfirmChangeLab'
import BtnLoader from '../../components/common/BtnLoader'
import { getCiyReducer, getStateReducer } from '../../utils/store/profile/ProfileSlice'

const LabtestCart = () => {
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const [date, setDate] = useState<string | null>(null)
    const [selectedTime, setSelectedTime] = useState<string | null>(null)
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false)
    const [particularItem, setParticularItem] = useState<CartItem | null>(null)
    const [city, setCity] = useState('');
    const [labList, setLabList] = useState<LabDetails | null>(null)
    const [selectedLab, setSelectedLab] = useState<number | null>(null);
    const [selectedBranch, setSelectedBranch] = useState<number | null>(null)
    const [branchList, setBranchList] = useState<branchListTypes[] | []>([])
    const [openAddMore, setOpenAddMore] = useState(false)
    const [SelectedLabName, setSelectedLabName] = useState('');
    const [SelectedBranchAddress, setSelectedBranchAddress] = useState('');
    const [openLabChange, setOpenLabChange] = useState(false)
    const [showAddMore, setShowAddMore] = useState(false)
    const [isRemoveAll, setIsRemoveAll] = useState(false)
    const [availableSlots, setAvailableSlots] = useState<string[] | []>([]);
    const [cartDetailsRadiology, setCartDetailsRadiology] = useState<CartDRadio[] | []>([])
    const [labDetailsForChangeLabData, setLabDetailsForChangeLabData] = useState<LabDetailsForChangeLabRes[] | null>([])
    const [updateAddressStates, setUpdateAddressStates] = useState({
        address: '',
        pincode: '',
        state: '',
        city: '',
    })
    const [btnLoadingUpdateAdd, setBtnLoadingUpdateAdd] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { CartItem, loading, LabCityData } = useSelector((state: RootState) => state.labtest)
    const { stateData, cityData } = useSelector((state: RootState) => state.profile);

    const location = useLocation();


    const fetchLabListByCity = async (data: any) => {
        try {
            const result = await getLabListByCity(data);
            if (result?.success) {
                setLabList(result?.labDetails)
            }
            else {
                setLabList(null)
            }
        } catch (error: any) {
            setLabList(null)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const handleSelectCity = (e: SelectChangeEvent<string>) => {
        const { value } = e.target;
        setCity(value)
        fetchLabListByCity({
            companyId: LoginData?.companyId,
            patientId: LoginData?.loggedPatientId,
            cityName: value
        })
        setLabList(null)
        setBranchList([])
        setSelectedLabName('')
        setSelectedBranch(null)
        setSelectedLab(null)
    }

    const fetchLabBranch = async (data: any) => {
        try {
            const result = await getLabBranchesApi(data)
            if (result?.success) {
                setBranchList(result?.branches)
            }
            else {
                setBranchList([])
            }
        } catch (error: any) {
            setBranchList([])
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const handleSelectLab = (event: React.ChangeEvent<HTMLInputElement>, item: LabDetail) => {
        const value = parseInt(event.target.value)
        setSelectedLabName(item?.labName)
        if (value) {
            setSelectedLab(value);
            const cityId = LabCityData?.filter((item) => item?.cityName === city)
            fetchLabBranch({ labId: value, labCityId: cityId && cityId[0]?.id })
            setSelectedBranch(null)
        }
    };
    const handleSelectBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        if (value) {
            setSelectedBranch(value);
        }
    };

    const handleDateChange = (newDate: Moment | null) => {
        setDate(newDate ? newDate.format('YYYY-MM-DD') : null)
    }
    const handleTimeSelection = (time: string) => {
        setSelectedTime(time)
    }

    const fetchLabDetailsForChangeLab = async (id: number) => {
        try {
            const result = await labDetailsByCartIdForChangeLabApi(id)
            if (result?.success) {
                if (result?.data?.length > 0) {
                    const _Data = result?.data[0] as LabDetailsForChangeLabRes
                    if (_Data?.labId) {
                        setShowAddMore(true)
                        setCity(_Data?.labCityName)
                        setSelectedLab(_Data?.labId);
                        setSelectedLabName(_Data?.labName);
                        setSelectedBranch(_Data?.labBranchId)
                        setSelectedBranchAddress(_Data?.branchAddress ? _Data?.branchAddress : _Data?.labCityName)
                        setLabDetailsForChangeLabData(result?.data)
                        setCartDetailsRadiology(result?.cartDetails);
                    } else (setLabDetailsForChangeLabData([]))
                } else (setLabDetailsForChangeLabData([]))
            }
            else {
                setLabDetailsForChangeLabData([])
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const fetchCartDetails = async (data: any) => {
        try {
            const result = await dispatch(cartDetailsReducer(data))
            if (result?.meta?.requestStatus === "fulfilled") {
                const _cartData = result?.payload && (result.payload as any[]).length > 0 && result?.payload && (result?.payload as any[])[0]
                if (_cartData.itemType === 'Test') {
                    setSelectedLab(_cartData?.labId)
                }
                if (_cartData?.itemMode === "Radiology") {
                    fetchLabDetailsForChangeLab(_cartData?.cartId)
                    setAvailableSlots(['8:00am to 9:00am'])
                }
                else {
                    setAvailableSlots(['6:00 to 7:00am', '6:30 to 7:30am', '7:00 to 8:00am', '7:30 to 8:30am', '8:00 to 9:00am', '8:30 to 9:30am', '9:00 to 10:00am', '9:30 to 10:30am', '10:00 to 11:00am', '10:30 to 11:30am'])
                }
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);

        }
    }

    const fetchLabHomeAddress = async () => {
        try {
            const result = await getLabHomeAddressApi();
            if (result?.success) {
                const Addr = result?.address as AddressTypes
                setUpdateAddressStates({
                    address: Addr?.address,
                    city: Addr?.city,
                    state: Addr?.state,
                    pincode: Addr?.zip_code
                })
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message)
        }
    }
    useEffect(() => {
        fetchCartDetails({ patientId: LoginData?.loggedPatientId })
        dispatch(getLabCityReducer())
        dispatch(getStateReducer());
        fetchLabHomeAddress();
    }, [location.pathname]);

    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }
    const handleRemoveCart = async (item: CartItem) => {
        setParticularItem(item)
        setOpenConfirmModal(true)
        setIsRemoveAll(false)
    }

    const handleFetchRemoveAll = async () => {
        try {
            setLoading(true)
            const result = await removeAllCartApi(CartItem && CartItem[0]?.cartId);
            if (result?.success) {
                setLoading(false)
                toast.success(result?.message)
                navigate('/LabTests/Book')
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const confirmSubmit = async () => {
        try {
            if (isRemoveAll) {
                handleFetchRemoveAll();
            }
            else {
                if (particularItem) {
                    setBtnLoading(true)
                    const result = await removeCartItemApi(particularItem?.itemId)
                    if (result.success) {
                        toast.success(result?.message)
                        setBtnLoading(false)
                        dispatch(cartDetailsReducer({ patientId: LoginData?.loggedPatientId }))
                        setOpenConfirmModal(false)
                    }
                    else {
                        setBtnLoading(false)
                    }
                }
            }
        } catch (error: any) {
            setBtnLoading(true)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const closeAddMore = () => {
        setOpenAddMore(false)
    }
    const handleAddMore = () => {
        setOpenAddMore(true)
        if (CartItem && CartItem[0]?.itemMode === "Pathology") {
            dispatch(PackageAndTestByLabApiReducer({ labId: CartItem[0]?.labId, cartId: CartItem && CartItem[0]?.cartId, companyId: LoginData?.companyId }))
            setSelectedLabName(CartItem && CartItem[0]?.labName || '')
        }
        else {
            dispatch(PackageAndTestByLabApiReducer({ labId: selectedLab, cartId: CartItem && CartItem[0]?.cartId, companyId: LoginData?.companyId }))
        }
    }

    const handleFreezeLab = async () => {
        try {
            setBtnLoading(true)
            const sendData = {
                "cartId": CartItem && CartItem[0]?.cartId,
                "labId": selectedLab,
                "labBranchId": selectedBranch,
                "labCityName": city
            }
            const result = await fixLabOnCartApi(sendData);
            if (result?.success) {
                if (result?.result && result?.result[0] === 1) {
                    fetchLabDetailsForChangeLab(CartItem && CartItem[0]?.cartId)
                }
                toast.success(result?.message)
                setShowAddMore(true)
                setBtnLoading(false)
            }
        } catch (error: any) {
            setBtnLoading(false)
            setShowAddMore(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const handleLabOrderPath = async () => {
        try {
            const cartData = CartItem.map((item) => {
                return {
                    type: item?.itemType === 'Test' ? 1 : 2,
                    referenceId: item?.referenceId,
                    companyIdPackageTest: item?.companyId,
                    patientCompanyId: LoginData?.companyId,
                    name: item?.packageName ? item?.packageName : item?.testName,
                    mode: item?.itemMode === "Pathology" ? 1 : 2,
                    noOfTest: item?.noOfTest,
                    price: parseFloat(item?.price),
                    discount: item?.discount,
                    total: item ? parseFloat(item.price) - parseFloat(item.discount) : 0
                }
            });

            const totalPrice = (CartItem as CartItem[]).reduce((acc, curr) => acc + (curr.price ? parseFloat(curr.price) : 0), 0);
            const discountApplied = (CartItem as CartItem[]).reduce((acc, curr) => acc + (curr.discount ? parseFloat(curr.discount) : 0), 0);

            const sendData = {
                cartId: CartItem && CartItem[0]?.cartId,
                patientId: LoginData?.loggedPatientId,
                bookingDate: date,
                bookingTime: selectedTime,
                bookingAddress: '',
                labId: CartItem && CartItem[0]?.labId,
                price: totalPrice,
                discountApplied: discountApplied,
                otherCharges: 0,
                totalPrice: totalPrice,
                orderDetails: cartData
            };

            const result = await labOrderApi(sendData);
            if (result?.success) {
                toast.success(result?.message);
                navigate('/LabTests/Cart_Summary', {
                    state: {
                        id: result?.labOrder?.id,
                        cartId: CartItem && CartItem[0]?.cartId
                    }
                })
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const handleLabOrderRadiology = async () => {
        try {
            const cartData = CartItem?.map((item) => {
                const cartDetail = cartDetailsRadiology?.find((cd) => cd.itemId === item.itemId);
                return {
                    type: item?.itemType === 'Test' ? 1 : 2,
                    referenceId: item?.referenceId,
                    companyIdPackageTest: item?.companyId,
                    patientCompanyId: LoginData?.companyId,
                    name: item?.packageName ? item?.packageName : item?.testName,
                    mode: item?.itemMode === "Pathology" ? 1 : 2,
                    noOfTest: item?.noOfTest,
                    price: cartDetail?.price,
                    discount: cartDetail?.discount,
                    total: cartDetail ? parseFloat(cartDetail.price) - parseFloat(cartDetail.discount) : 0
                }
            });
            const totalPrice = cartData.reduce((acc, curr) => acc + curr.total, 0);
            const discountApplied = cartData.reduce((acc, curr) => acc + (curr.discount ? parseFloat(curr.discount) : 0), 0);
            const sendData = {
                cartId: CartItem && CartItem[0]?.cartId,
                patientId: LoginData?.loggedPatientId,
                bookingDate: date,
                bookingTime: selectedTime,
                bookingAddress: SelectedBranchAddress,
                labId: selectedLab,
                // labCityName: branchAdd && branchAdd[0]?.branchAddress,
                // labBranchId: selectedBranch,
                price: totalPrice,
                discountApplied: discountApplied,
                otherCharges: 0,
                totalPrice: totalPrice,
                // isPaid: false,
                // orderStatus: "pending",
                // paymentStatus: "unpaid",
                // noOfTest: totalNoOfTest,
                orderDetails: cartData
            };

            const result = await labOrderApi(sendData);
            if (result?.success) {
                toast.success(result?.message);
                navigate('/LabTests/Cart_Summary', {
                    state: {
                        id: result?.labOrder?.id,
                        cartId: CartItem && CartItem[0]?.cartId
                    }
                })
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    };


    const CloseLabChange = () => {
        setOpenLabChange(false)
    }
    const confirmLabChange = () => {
        handleFetchRemoveAll();
    }

    const handleRemoveAll = async () => {
        setIsRemoveAll(true)
        setOpenConfirmModal(true)
    }

    const handleChangeLab = () => {
        setOpenLabChange(true)
    }

    const handleChangeUpdateAddress = (name: string, value: string) => {

        if (name === "state") {
            setUpdateAddressStates({
                ...updateAddressStates,
                city: '',
                state: value
            })
            const stateCode = stateData?.filter((item) => item?.state === value)
            if (stateCode?.length > 0) {
                dispatch(getCiyReducer({ state_id: stateCode[0]?.id }))
            }
        }
        else {
            setUpdateAddressStates({
                ...updateAddressStates,
                [name]: value
            })
        }
    }

    const handleUpdateAddress = async () => {
        try {
            const sendData = {
                address: updateAddressStates?.address,
                pincode: updateAddressStates?.pincode,
                state: updateAddressStates?.state,
                city: updateAddressStates?.city,
            }
            setBtnLoadingUpdateAdd(true)
            const result = await updateLabHomeAddressApi(sendData);
            if (result?.success) {
                setBtnLoadingUpdateAdd(false)
                toast.success(result?.message)
            }
        } catch (error: any) {
            setBtnLoadingUpdateAdd(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    if (loading) {
        return <Loader />
    }

    const minDate = CartItem && CartItem[0]?.itemMode === "Pathology"
        ? moment().add(1, 'days')
        : moment().add(2, 'days');

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4'><BackIcon onClick={() => navigate(-1)} />Cart</h2>
                </div>
                <div className={styles.cartMain}>
                    {
                        CartItem?.length > 0 ?
                            <div className='grid grid-cols-1 lg:grid-cols-7  lg:gap-6 gap-y-6'>
                                <section className={`lg:col-span-4 ${styles.cartLeft}`}>
                                    <div className={styles.addMoreAndCount}>
                                        <h3>{CartItem?.length} test added</h3>
                                        {
                                            (showAddMore || (CartItem && CartItem[0]?.itemMode === "Pathology")) &&
                                            <button className='fillButton' onClick={handleAddMore}>Add more tests</button>
                                        }
                                    </div>
                                    <div className='flex flex-col gap-4 mt-6'>
                                        {
                                            CartItem?.length > 1 &&
                                            <div className={styles.removeAll}>
                                                <button onClick={handleRemoveAll}>Remove All</button>
                                            </div>
                                        }
                                        {
                                            CartItem?.map((item, index) => {
                                                return (
                                                    <div className={styles.addListsMain} key={index}>
                                                        <div className={styles.listLeft}>
                                                            {
                                                                item?.itemType === "Package" ?
                                                                    <div className={styles.testsContent}>
                                                                        <h3>{item?.packageName} ({item?.noOfTest} tests included)</h3>
                                                                        <p>{item?.categoryName}</p>
                                                                    </div>
                                                                    :
                                                                    <div className={styles.testsContent}>
                                                                        <h3>{item?.testName}</h3>
                                                                        <p>{item?.categoryName}</p>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className={styles.listRight}>
                                                            <div className={styles.remove}>
                                                                <p onClick={() => handleRemoveCart(item)}>Remove</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className={styles.selectLabTest}>
                                        {
                                            CartItem && CartItem[0]?.itemMode === "Pathology" ?
                                                <div>
                                                    <div className={styles.ThisIsHomeTest}>
                                                        <h4>This is home test</h4>
                                                    </div>
                                                    <form>
                                                        <div className={`${styles.otherInfoHead}`}>
                                                            <h3>Update address</h3>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                                                            <FormGroup className="customFormGroup">
                                                                <FormLabel className="customFormLabel">Location</FormLabel>
                                                                <TextField
                                                                    type="text"
                                                                    className={`custumTextField profileInput`}
                                                                    name="address"
                                                                    value={updateAddressStates?.address}
                                                                    onChange={(e) => handleChangeUpdateAddress('address', e?.target?.value)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className="customFormGroup">
                                                                <FormLabel className="customFormLabel">Pin Code</FormLabel>
                                                                <TextField
                                                                    type="text"
                                                                    className={`custumTextField profileInput`}
                                                                    name="pincode"
                                                                    value={updateAddressStates?.pincode}
                                                                    onChange={(e) => handleChangeUpdateAddress('pincode', e?.target?.value)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className="customFormGroup">
                                                                <FormLabel className="customFormLabel">State</FormLabel>
                                                                <Select
                                                                    className={`custumTextField profileInput`}
                                                                    name='state'
                                                                    value={updateAddressStates?.state}
                                                                    onChange={(e) => handleChangeUpdateAddress('state', e?.target?.value)}
                                                                >
                                                                    {
                                                                        stateData?.length > 0 && stateData?.map((item, index) => {
                                                                            return (
                                                                                <MenuItem key={index} value={item?.state}>{item?.state}</MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormGroup>
                                                            <FormGroup className="customFormGroup">
                                                                <FormLabel className="customFormLabel">City</FormLabel>
                                                                <Select
                                                                    className={`custumTextField profileInput`}
                                                                    name='city'
                                                                    value={updateAddressStates?.city}
                                                                    onChange={(e) => handleChangeUpdateAddress('city', e?.target?.value)}
                                                                >
                                                                    {
                                                                        cityData?.length > 0 ? cityData?.map((item, index) => {
                                                                            return (
                                                                                <MenuItem key={index} value={item?.city}>{item?.city}</MenuItem>
                                                                            )
                                                                        })
                                                                            :
                                                                            <MenuItem value={updateAddressStates?.city}>{updateAddressStates?.city}</MenuItem>
                                                                    }
                                                                </Select>
                                                            </FormGroup>
                                                        </div>
                                                        <button type='button' onClick={handleUpdateAddress} className='fillButton mt-8' disabled={btnLoadingUpdateAdd}> {btnLoadingUpdateAdd && <BtnLoader />} Update</button>
                                                    </form>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        showAddMore ? (
                                                            <>
                                                                <div className={`${styles.removeAll} mt-6`}>
                                                                    <button onClick={handleChangeLab}>Change Lab</button>
                                                                </div>
                                                                <div className="grid grid-cols-1 lg:grid-cols-2 my-4">
                                                                    <FormGroup className="customFormGroup">
                                                                        <FormLabel className="customFormLabel">Select City</FormLabel>
                                                                        <Select
                                                                            className={`custumTextField profileInput`}
                                                                            placeholder="Select city"
                                                                            value={city}
                                                                            name='city'
                                                                            disabled={true}
                                                                            onChange={handleSelectCity}
                                                                        >
                                                                            {
                                                                                LabCityData?.length > 0 && LabCityData?.map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem key={index} value={item?.cityName}>{item?.cityName}</MenuItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className='flex flex-col gap-4 mt-4'>
                                                                    {
                                                                        labDetailsForChangeLabData?.map((item, index) => {
                                                                            return (
                                                                                <div className={`${styles.LabLists}`} key={index}>
                                                                                    <RadioGroup
                                                                                        value={item?.labId?.toString() || ''}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            value={item?.labId?.toString()}
                                                                                            control={<Radio disabled className='customRadio' />}
                                                                                            label={
                                                                                                <div className={styles.labSelectBox}>
                                                                                                    <h3>{item?.labName}</h3>
                                                                                                    <p>{item?.labDescription}</p>
                                                                                                </div>
                                                                                            }
                                                                                            className='profileRadioLabel labTestRadio'
                                                                                        />
                                                                                    </RadioGroup>

                                                                                    <div className={styles.labBranchMain}>
                                                                                        <RadioGroup
                                                                                            value={item?.labBranchId?.toString() || ''}
                                                                                        >
                                                                                            <FormControlLabel
                                                                                                value={item?.labBranchId?.toString()}
                                                                                                control={<Radio disabled className='customRadio' />}
                                                                                                label={
                                                                                                    <div className={styles.labSelectBox}>
                                                                                                        <h3>{item?.branchName}</h3>
                                                                                                        <p>{item?.branchAddress}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                className='profileRadioLabel labTestRadio'
                                                                                            />
                                                                                        </RadioGroup>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </>)
                                                            :
                                                            <>
                                                                <div className="grid grid-cols-1 lg:grid-cols-2 my-4">
                                                                    <FormGroup className="customFormGroup">
                                                                        <FormLabel className="customFormLabel">Select City</FormLabel>
                                                                        <Select
                                                                            className={`custumTextField profileInput`}
                                                                            placeholder="Select city"
                                                                            value={city}
                                                                            name='city'
                                                                            onChange={handleSelectCity}
                                                                        >
                                                                            {
                                                                                LabCityData?.length > 0 && LabCityData?.map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem key={index} value={item?.cityName}>{item?.cityName}</MenuItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className='flex flex-col gap-4 mt-4'>
                                                                    {
                                                                        labList?.labDetails?.map((item, index) => {
                                                                            return (
                                                                                <div className={`${styles.LabLists}`} key={index}>
                                                                                    <RadioGroup
                                                                                        value={selectedLab?.toString() || ''}
                                                                                        onChange={(e) => handleSelectLab(e, item)}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            value={item?.labId?.toString()}
                                                                                            control={<Radio className='customRadio' />}
                                                                                            label={
                                                                                                <div className={styles.labSelectBox}>
                                                                                                    <h3>{item?.labName} <span>Rs {item?.price}</span></h3>
                                                                                                    <p>{item?.labDescription}</p>
                                                                                                </div>
                                                                                            }
                                                                                            className='profileRadioLabel labTestRadio'
                                                                                        />
                                                                                    </RadioGroup>
                                                                                    {
                                                                                        item?.labId === selectedLab &&
                                                                                        <div className={styles.labBranchMain}>
                                                                                            {
                                                                                                branchList?.map((branchItem, branchIndex) => {
                                                                                                    return (
                                                                                                        <RadioGroup
                                                                                                            value={selectedBranch?.toString() || ''}
                                                                                                            onChange={handleSelectBranch}
                                                                                                            key={branchIndex}
                                                                                                        >
                                                                                                            <FormControlLabel
                                                                                                                value={branchItem?.id.toString()}
                                                                                                                control={<Radio className='customRadio' />}
                                                                                                                label={
                                                                                                                    <div className={styles.labSelectBox}>
                                                                                                                        <h3>{branchItem?.branchName}</h3>
                                                                                                                        <p>{branchItem?.branchAddress}</p>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                className='profileRadioLabel labTestRadio'
                                                                                                            />
                                                                                                        </RadioGroup>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                                <div className='mt-4'>
                                                                    <button className='fillButton'
                                                                        disabled={btnLoading || (!selectedLab || !selectedBranch)}
                                                                        onClick={handleFreezeLab}
                                                                    >
                                                                        {btnLoading && <BtnLoader />}
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </>
                                                    }

                                                </>
                                        }
                                    </div>
                                </section>
                                {
                                    (showAddMore || (CartItem && CartItem[0]?.itemMode === "Pathology")) &&
                                    <section className={`lg:col-span-3 ${styles.cartRight}`}>
                                        <div className={styles.selectDateTime}>
                                            <div className={styles.BCalenderHead}>
                                                <h3>Select a Date & Time</h3>
                                            </div>
                                            <div className='CustomcalenderWrapper'>
                                                <DateCalendar
                                                    minDate={minDate}
                                                    className='customDateCalendar'
                                                    value={date ? moment(date, 'YYYY-MM-DD') : null}
                                                    onChange={(newDate) => handleDateChange(newDate)}
                                                />
                                            </div>
                                            <div className={styles.availableAppointment}>
                                                <div className="grid grid-cols-3 gap-4 pt-4">
                                                    {availableSlots?.map((time) => (
                                                        <div
                                                            key={time}
                                                            className={`${styles.availableApBox} ${selectedTime === time ? styles.selectedTime : ''}`}
                                                            onClick={() => handleTimeSelection(time)}
                                                        >
                                                            <p>{time}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className='flex justify-end mt-8'>
                                                {
                                                    (CartItem && CartItem[0]?.itemMode === "Pathology") ?
                                                        <button className='fillButton' disabled={btnLoading || (!date || !selectedTime)} onClick={handleLabOrderPath}> {btnLoading && <BtnLoader />} Proceed</button>
                                                        :
                                                        <button className='fillButton' disabled={btnLoading || (!date || !selectedTime)} onClick={handleLabOrderRadiology}> {btnLoading && <BtnLoader />} Proceed</button>
                                                }
                                            </div>
                                        </div>
                                    </section>
                                }
                            </div>
                            :
                            <NoDataFound />
                    }
                </div>
            </div>
            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                btnLoading={btnLoading}
                confirmSubmit={confirmSubmit}
                title={`Are you sure, you want to remove ${isRemoveAll ? 'all cart' : 'this'} item?`}
            />
            <AddMoreLabTest
                openAddMore={openAddMore}
                closeAddMore={closeAddMore}
                selectedLab={selectedLab}
                SelectedLabName={SelectedLabName}
                fetchLabDetailsForChangeLab={fetchLabDetailsForChangeLab}
            />
            <ConfirmChangeLab
                openLabChange={openLabChange}
                CloseLabChange={CloseLabChange}
                btnLoading={btnLoading}
                confirmLabChange={confirmLabChange}
            />
        </CustomContainer>
    )
}

export default LabtestCart