import ThanksImg from "../../assets/images/common/thanks.png"
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/appointments.module.css"
import { useNavigate } from "react-router-dom"

const BookAppointmentThanks = () => {
    const navigate = useNavigate();
    return (
        <CustomContainer heightFull={true}>
            <div className="flex justify-center items-center flex-col text-center h-full">
                <div className={styles.BookThanksMain}>
                    <img src={ThanksImg} alt="thanks for booking" />
                    <h3>Thanks for choosing AkosMD</h3>
                    <p>You can check your appointment status and details in the Appointment section</p>
                    <button className="fillButton mt-6" onClick={() => navigate('/MyAppointments')}>Go to Appointments</button>
                </div>
            </div>
        </CustomContainer>
    )
}

export default BookAppointmentThanks