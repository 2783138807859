import React from 'react';
import styles from '../../assets/styles/careplan.module.css';

type CardProps = {
    data: CardData;
};

const PlanCard: React.FC<CardProps> = ({ data }) => {
    const useValue = data?.value?.split('/') && data?.value?.split('/')?.length > 1 && data?.value?.split('/')[1] || ''
    return (
        <div className={styles.planCard}>
            <div className={styles.planCardTitle}>
                <h3>{data.title}</h3>
                <hr className='hrLine hrM4' />
            </div>
            <div className={styles.valueContainer}>
                <h3 dangerouslySetInnerHTML={{ __html: data?.value?.replace(`/${useValue}`, `<span class='useValuesss'>/${useValue}</span>`) }} />
                {(data.subValue || data.frequency) && (
                    <span dangerouslySetInnerHTML={{ __html: (data.subValue || data.frequency).replace(/\s+/g, '<br />') }} />
                )}
            </div>
        </div>
    );
};

export default PlanCard;
