import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import store from "../store";
import { clearAuth, setAuthToken } from "../store/auth/AuthSlice";
import { BASE_URL } from "./routes";
import { decryptData, encryptData } from "../functions/cryptoUtils";
import { EncryptedData } from "../functions/cryptoUtils";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

const axiosWithAuth = axios.create();
interface DecryptedErrorData {
  message: string;
  [key: string]: any; 
}
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

axiosWithAuth.interceptors.request.use(
  async (config: CustomAxiosRequestConfig) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["role"] = "patient"; 
    }

    if (config.data) {
      try {
        const encryptedDataResult: EncryptedData | undefined = encryptData(config.data);
        if (encryptedDataResult) {
          const { IV, encryptedData } = encryptedDataResult;
          config.data = { encryptedData, IV: IV };
        } else {
          console.error("Encryption failed: No result returned");
          return Promise.reject(new Error("Encryption failed"));
        }
      } catch (error) {
        console.error("Encryption error:", error);
        return Promise.reject(error);
      }
    }

    if (config.method === 'get' && config.params) {
      try {
        const encryptedParamsResult: EncryptedData | undefined = encryptData(config.params);
        if (encryptedParamsResult) {
          const { IV, encryptedData } = encryptedParamsResult;
          config.params = { encryptedData, IV: IV };
        } else {
          console.error("Encryption failed for params: No result returned");
          return Promise.reject(new Error("Encryption failed for params"));
        }
      } catch (error) {
        console.error("Encryption error for params:", error);
        return Promise.reject(error);
      }
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axiosWithAuth.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data?.encryptedData && response.data?.IV) {
      try {
        const decryptedData = decryptData(response.data.encryptedData, response.data.IV);
        if (decryptedData) {
          if (typeof decryptedData === "object" && decryptedData !== null) {
            response.data = decryptedData;
          } else {
            console.error("Decrypted data is not a valid object:", decryptedData);
            return Promise.reject(new Error("Decryption failed: Invalid data format"));
          }
        } else {
          console.error("Decrypted data is undefined");
          return Promise.reject(new Error("Decryption failed: No data returned"));
        }
      } catch (error) {
        console.error("Decryption error:", error);
        return Promise.reject(error);
      }
    }
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
    if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = Cookies.get("refreshToken");
        const { data } = await axiosWithAuth.post("/refresh-token", { refreshToken });
        const newToken = data.token;

        Cookies.set("token", newToken);
        store.dispatch(setAuthToken(newToken));
        axiosWithAuth.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;

        if (originalRequest.headers) {
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        }

        return axiosWithAuth(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        Cookies.remove("token");
        Cookies.remove("refreshToken");
        store.dispatch(clearAuth());
        return Promise.reject(refreshError);
      }
    }

    console.error("Response error:", error);
    const encryptedErr = error.response?.data as EncryptedData; 
    if (encryptedErr) {
      if (encryptedErr?.encryptedData && encryptedErr?.IV) {
        try {
          const decryptedData = decryptData(encryptedErr.encryptedData, encryptedErr.IV);
          console.log("Decrypted Error Data:", decryptedData);

          if (decryptedData && typeof decryptedData === "object" && decryptedData !== null) {
            const typedErrorData = decryptedData as DecryptedErrorData;
            error.message = typedErrorData.message || "An error occurred, but no message was provided.";
            if (error.response) { 
              error.response.data = typedErrorData; 
            }
          } else {
            console.error("Decrypted data is not an object:", decryptedData);
            error.message = "Failed to parse error response.";
          }
        } catch (decryptionError) {
          console.error("Error decrypting response data:", decryptionError);
          error.message = "Failed to decrypt error response.";
        }
      } else {
        console.error("No encrypted error data found in response.");
      }
    } else {
      console.error("No error response data available.");
    }

    return Promise.reject(error);
  }
);

export default axiosWithAuth;
