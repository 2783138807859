import React, { FC } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from "../../assets/styles/assessments.module.css";

interface PropsTypes {
    item: HraResTypes;
}

const HraCard: FC<PropsTypes> = ({ item }) => {
    return (
        <div className={styles.HraCard}>
            <h3>{item.title}</h3>
            <div style={{ width: 150, height: 150, position: 'relative' }}>
                <CircularProgressbar
                    value={item?.percentage}
                    text={null}
                    styles={buildStyles({
                        pathColor: item.color,
                        trailColor: '#E9EDF0',
                    })}
                />
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center'
                }}
                    className={styles.progressTextAndIcon}
                >
                    <div className={styles.hraIcon}>
                        {item?.imgUrl}
                    </div>
                    <p>{`${item?.percentage}%`}</p>
                </div>
            </div>
            <p className={styles.description}>
                {item?.result}
            </p>
        </div>
    );
};

export default HraCard;
