import React, { useState } from "react";

const BLOOD_PRESSURE_SERVICE_UUID = "00001810-0000-1000-8000-00805f9b34fb";
const BLOOD_PRESSURE_CHARACTERISTIC_UUID = "00002a35-0000-1000-8000-00805f9b34fb";
const BATTERY_SERVICE_UUID = "0000180f-0000-1000-8000-00805f9b34fb";
const BATTERY_LEVEL_CHARACTERISTIC_UUID = "00002a19-0000-1000-8000-00805f9b34fb";
const DEVICE_INFORMATION_UUID = "0000180a-0000-1000-8000-00805f9b34fb";
const ECG_SERVICE_UUID = "6e800001-b5a3-f393-e0a9-e50e24dcca9e";
const ECG_MEASUREMENT_CHARACTERISTIC_UUID = "6e800004-b5a3-f393-e0a9-e50e24dcca9e";

const decodeSfloat = (rawValue) => {
  const exponent = (rawValue & 0xf000) >> 12;
  const mantissa = rawValue & 0x0fff;

  const actualExponent = exponent >= 0x08 ? exponent - 0x10 : exponent;
  const actualMantissa = mantissa >= 0x0800 ? mantissa - 0x1000 : mantissa;

  return actualMantissa * Math.pow(10, actualExponent);
};

const decodeECGValue = (value) => {
  return value >= 0x8000 ? value - 0x10000 : value;
};

const BM96Reader = () => {
  const [bpData, setBpData] = useState(null);
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState({});
  const [ecgData, setEcgData] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");

  const connectToDevice = async () => {
    try {
      console.log("Requesting Bluetooth Device...");
      const device = await navigator.bluetooth.requestDevice({
        filters: [
          { namePrefix: "BM96" },
          { services: [BLOOD_PRESSURE_SERVICE_UUID, ECG_SERVICE_UUID] },
        ],
        optionalServices: [BATTERY_SERVICE_UUID, DEVICE_INFORMATION_UUID],
      });

      const server = await device.gatt.connect();
      setConnectionStatus("Connected");

      await readBloodPressureData(server);

      await readBatteryLevel(server);

      await readDeviceInformation(server);

      console.log("Reading ECG Data...");
      await readECGData(server);
    } catch (error) {
      console.error("Error connecting to device:", error);
      setConnectionStatus("Error");
    }
  };

  const readBloodPressureData = async (server) => {
    const service = await server.getPrimaryService(BLOOD_PRESSURE_SERVICE_UUID);
    const characteristic = await service.getCharacteristic(
      BLOOD_PRESSURE_CHARACTERISTIC_UUID
    );

    characteristic.addEventListener("characteristicvaluechanged", (event) => {
      const value = event.target.value;
      const data = new DataView(value.buffer);

      const flags = data.getUint8(0);
      const pulseRateFlag = (flags & 0x04) !== 0;
      const systolic = decodeSfloat(data.getUint16(1, true));
      const diastolic = decodeSfloat(data.getUint16(3, true));
      let pulseRate = "Invalid";
      if (pulseRateFlag) {
        const rawPulseRate = data.getUint16(5, true);
        pulseRate = rawPulseRate === 0x07FF ? "Invalid" : decodeSfloat(rawPulseRate);
      }

      const timestamp = `${data.getUint16(7, true)}-${data
        .getUint8(9)
        .toString()
        .padStart(2, "0")}-${data.getUint8(10).toString().padStart(2, "0")} ${data
        .getUint8(11)
        .toString()
        .padStart(2, "0")}:${data.getUint8(12).toString().padStart(2, "0")}:${data
        .getUint8(13)
        .toString()
        .padStart(2, "0")}`;

      setBpData({ systolic, diastolic, pulseRate, timestamp });
    });

    await characteristic.startNotifications();
  };

  const readBatteryLevel = async (server) => {
    const service = await server.getPrimaryService(BATTERY_SERVICE_UUID);
    const characteristic = await service.getCharacteristic(
      BATTERY_LEVEL_CHARACTERISTIC_UUID
    );

    const value = await characteristic.readValue();
    setBatteryLevel(value.getUint8(0));
  };

  const readDeviceInformation = async (server) => {
    const service = await server.getPrimaryService(DEVICE_INFORMATION_UUID);

    const manufacturerCharacteristic = await service.getCharacteristic(
      "00002a29-0000-1000-8000-00805f9b34fb"
    );
    const manufacturerValue = await manufacturerCharacteristic.readValue();
    const manufacturer = new TextDecoder().decode(manufacturerValue);

    const modelCharacteristic = await service.getCharacteristic(
      "00002a24-0000-1000-8000-00805f9b34fb"
    );
    const modelValue = await modelCharacteristic.readValue();
    const model = new TextDecoder().decode(modelValue);

    setDeviceInfo({ manufacturer, model });
  };

  const readECGData = async (server) => {
    try {
      const service = await server.getPrimaryService(ECG_SERVICE_UUID);
  
      const controlPointCharacteristic = await service.getCharacteristic(
        "6e800002-b5a3-f393-e0a9-e50e24dcca9e"
      );
      const command = new Uint8Array([0x03, 0x01]); 
      await controlPointCharacteristic.writeValue(command);
      console.log("Command to start ECG data sent");
  
      const characteristic = await service.getCharacteristic(
        ECG_MEASUREMENT_CHARACTERISTIC_UUID
      );
      console.log("Obtained ECG Characteristic:", characteristic);
  
      characteristic.addEventListener("characteristicvaluechanged", (event) => {
        console.log("ECG Notification Received");
        const value = event.target.value;
        const data = new DataView(value.buffer);
  
        const ecgValues = [];
        for (let i = 0; i < data.byteLength; i += 2) {
          ecgValues.push(decodeECGValue(data.getInt16(i, true)));
        }
  
        console.log("Decoded ECG Data:", ecgValues);
        setEcgData((prevData) => [...prevData, ...ecgValues]);
      });
  
      await characteristic.startNotifications();
      console.log("ECG Notifications Successfully Started");
    } catch (error) {
      console.error("Error in readECGData:", error);
    }
  };
  
  
  

  console.log(ecgData, "ecgData")
  return (
    <div>
      <h1>BM96 Reader</h1>
      <button onClick={connectToDevice}>Connect to BM96</button>
      <p>Status: {connectionStatus}</p>

      {bpData && (
        <div>
          <h2>Blood Pressure</h2>
          <p>Systolic: {bpData.systolic.toFixed(1)} mmHg</p>
          <p>Diastolic: {bpData.diastolic.toFixed(1)} mmHg</p>
          <p>
            Pulse Rate:{" "}
            {bpData.pulseRate === "Invalid"
              ? "Pulse rate not available."
              : `${bpData.pulseRate} bpm`}
          </p>
          <p>Timestamp: {bpData.timestamp}</p>
        </div>
      )}

      {batteryLevel !== null && (
        <div>
          <h2>Battery Level</h2>
          <p>{batteryLevel}%</p>
        </div>
      )}

      {deviceInfo.manufacturer && (
        <div>
          <h2>Device Information</h2>
          <p>Manufacturer: {deviceInfo.manufacturer}</p>
          <p>Model: {deviceInfo.model}</p>
        </div>
      )}

      {ecgData.length > 0 && (
        <div>
          <h2>ECG Data</h2>
          <ul>
            {ecgData.map((value, index) => (
              <li key={index}>{value} µV</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BM96Reader;
