import { useEffect, useState } from 'react';
import CustomContainer from '../../components/common/CustomContainer';
import styles from "../../assets/styles/appointments.module.css";
import { BackIcon, FileIcon, MassageIcon } from '../../utils/functions/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import UserScore from '../../components/common/UserScore';
import style from "../../assets/styles/dashboard.module.css";
import rxIcon from "../../assets/images/common/rxicon.svg";
import MedicineListTable from '../../components/myAppointments/MedicineListTable';
import { AppointmentDetaisApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import { Images } from '../../utils/functions/Images';
import { handleViewDocument } from '../../utils/store/common/CommonSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../utils/store';
import DocumentViewModal from '../../components/savedDocuments/DocumentViewModal';
import NoDataFound from '../../components/common/NoDataFound';

const AppointmentDetails = () => {
    const [appointmentDetails, setAppointmentDetails] = useState<AppointmentDetailsResponse | null>(null);
    const [particularData, setParticularData] = useState<appointmentTypes | null>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    const fetchAppointmentDetails = async (id: number) => {
        try {
            setLoading(true);
            const result = await AppointmentDetaisApi(id);
            if (result?.success) {
                setLoading(false);
                setAppointmentDetails(result?.response);
            } else {
                setLoading(true);
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);;
        }
    };

    useEffect(() => {
        if (location?.state?.id) {
            fetchAppointmentDetails(location?.state?.id);
            setParticularData(location?.state?.item);
        }
    }, [location.pathname]);

    const handleViewPrescription = (item: appointmentTypes, fileData?: File | { fileURL: string | null | undefined }) => {
        if (item) {
            dispatch(handleViewDocument({
                dateTime: item?.dateTime,
                doctorName: item?.doctorName,
                experience: item?.experience,
                prescriptionURL: fileData?.fileURL ? fileData?.fileURL : item?.prescriptionURL ? item?.prescriptionURL : '',
                speciality: item?.speciality
            }))
        }
    }
    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4'>
                        <BackIcon onClick={() => navigate(-1)} />Appointment {particularData?.dateTime}
                    </h2>
                </div>
                {
                    location?.state?.id ?
                        <div className={styles.appointmentDetailsPage}>
                            <div className="grid grid-cols-1 xl:grid-cols-7 xl:gap-4 gap-y-4">
                                <section className={`col-span-5 ${styles.ADetailsLeft}`}>
                                    <UserScore patientDetails={appointmentDetails?.patientDetails} healthDetails={appointmentDetails?.healthDetails} />
                                    <div className={styles.ADPrescribedMedicine}>
                                        {
                                            appointmentDetails?.medicinePrescribed &&
                                            <>
                                                <div className={styles.ADPMhead}>
                                                    <h3>Medicine Prescribed</h3>
                                                    <img src={rxIcon} alt="rx icon" />
                                                </div>
                                                <div className={styles.ADPMedicineList}>
                                                    <MedicineListTable medicinePrescribed={appointmentDetails?.medicinePrescribed} />
                                                </div>
                                            </>
                                        }
                                        {
                                            appointmentDetails?.connectCallLog?.diagnosis &&
                                            <div className={styles.ADPOtherDetails}>
                                                <div className={styles.ADPOHead}>
                                                    <h3>Diagnosis</h3>
                                                </div>
                                                <div className={styles.ADPOContent}>
                                                    <div dangerouslySetInnerHTML={{ __html: appointmentDetails?.connectCallLog?.diagnosis }} />
                                                </div>
                                            </div>
                                        }
                                        {
                                            appointmentDetails?.connectCallLog?.lab_findings &&
                                            <div className={styles.ADPOtherDetails}>
                                                <div className={styles.ADPOHead}>
                                                    <h3>Examination/ Lab findings</h3>
                                                </div>
                                                <div className={styles.ADPOContent}>
                                                    <div dangerouslySetInnerHTML={{ __html: appointmentDetails?.connectCallLog?.lab_findings }} />
                                                </div>
                                            </div>
                                        }
                                        {
                                            appointmentDetails?.connectCallLog?.suggested_investigations &&
                                            <div className={styles.ADPOtherDetails}>
                                                <div className={styles.ADPOHead}>
                                                    <h3>Suggested Investigations</h3>
                                                </div>
                                                <div className={styles.ADPOContent}>
                                                    <div dangerouslySetInnerHTML={{ __html: appointmentDetails?.connectCallLog?.suggested_investigations }} />
                                                </div>
                                            </div>
                                        }
                                        {
                                            appointmentDetails?.connectCallLog?.special_instructions &&
                                            <div className={styles.ADPOtherDetails}>
                                                <div className={styles.ADPOHead}>
                                                    <h3>Special Instructions</h3>
                                                </div>
                                                <div className={styles.ADPOContent}>
                                                    <div dangerouslySetInnerHTML={{ __html: appointmentDetails?.connectCallLog?.special_instructions }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </section>
                                <section className={`col-span-2 ${styles.ADetailsRight}`}>
                                    <div className={styles.downloadPresBtn}>
                                        <button className='fillButton' onClick={() => particularData && handleViewPrescription(particularData, { fileURL: appointmentDetails?.prescriptionURL })}>Download Prescription</button>
                                    </div>
                                    <section className={` mt-4 ${style.rightSection}`}>
                                        {
                                            appointmentDetails?.doctorDetails &&
                                            <section className={style.upcomingAppointmentsec}>
                                                <div className={style.upcomingAppointmentMain}>
                                                    <div className={`${style.UAHeadAndDot} ${style.APDUAHeadAndDot}`}>
                                                        <div className={style.UAHead}>
                                                            <h3>Meet the doctor</h3>
                                                        </div>
                                                        {/* <div className="pt-2">
                                                            <div className={style.ADchatNow}><MassageIcon /> <span>Chat now</span></div>
                                                        </div> */}
                                                    </div>
                                                    <div className={style.APDDetailsMain}>
                                                        <div className={style.APDDetailsUserImg}>
                                                            <img src={appointmentDetails?.doctorDetails?.profilePic ? appointmentDetails?.doctorDetails?.profilePic : Images.avtarIcon} alt="user image" />
                                                        </div>
                                                        <div className={style.APDDetailsContent}>
                                                            <h4>{appointmentDetails?.doctorDetails?.name}</h4>
                                                            <p>{appointmentDetails?.doctorDetails?.speciality}</p>
                                                        </div>
                                                    </div>
                                                    <div className={style.docAndDateSec}>
                                                        <div className={style.docDate}>
                                                            <p>{appointmentDetails?.doctorDetails?.appointmentDate} <span>({appointmentDetails?.doctorDetails?.appointmentTime})</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            appointmentDetails?.connectCallLog?.allergies &&
                                            <section className={style.careTeamSec}>
                                                <div className={style.AllSecHead}>
                                                    <h3>Allergies found</h3>
                                                </div>
                                                <div className="grid grid-cols-2 gap-2">
                                                    {appointmentDetails?.connectCallLog.allergies.map((allergy, index) => (
                                                        <div key={index} className={style.ALLergyCon}>
                                                            <p>{allergy}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </section>
                                        }
                                        {
                                            appointmentDetails?.files && appointmentDetails?.files?.length > 0 &&
                                            <section className={`${style.careTeamSec}`}>
                                                <div className={style.AllSecHead}>
                                                    <h3>Files</h3>
                                                </div>
                                                <ul className={styles.fileLists}>
                                                    {appointmentDetails?.files?.map((item, index) => {
                                                        return (
                                                            <li key={index} onClick={() => particularData && handleViewPrescription(particularData, item)}>
                                                                <FileIcon />
                                                                <span>{item?.fileName}</span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </section>
                                        }
                                        {
                                            appointmentDetails?.connectCallLog?.chief_complaints &&
                                            <section className={style.careTeamSec}>
                                                <div className={style.AllSecHead}>
                                                    <h3>Chief Complaints</h3>
                                                </div>
                                                <div className={style.NotesC}>
                                                    <div dangerouslySetInnerHTML={{ __html: appointmentDetails?.connectCallLog?.chief_complaints }} />
                                                </div>
                                            </section>
                                        }
                                    </section>
                                </section>
                            </div>
                        </div>
                        :
                        <NoDataFound />
                }
            </div>
            <DocumentViewModal />
        </CustomContainer >
    );
}

export default AppointmentDetails;
