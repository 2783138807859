import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import userIcon from "../../assets/images/team/1.png"
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import { PlusLogoIcon, SearchIcon } from '../../utils/functions/Icons';
import logo from "../../assets/images/common/mchemistlogo.png"
import bgImg from "../../assets/images/common/completedbg.png"
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { fetchCompletedAppReducer } from '../../utils/store/appointment/AppointmentSlice';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { useNavigate } from 'react-router-dom';
import { handleViewDocument } from '../../utils/store/common/CommonSlice';
import DocumentViewModal from '../savedDocuments/DocumentViewModal';
import { Images } from '../../utils/functions/Images';
import { SsoMchemistLoginApi } from '../../utils/api/services';
import { MCHEMIST_REDIRECT } from '../../utils/api/routes';
export default function Completed() {
    const [searchValue, setSearchValue] = useState('')
    const { loading, CompletedAppointment, } = useSelector((state: RootState) => state.appointment)
    const { LoginData } = useSelector((state: RootState) => state.auth)

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(fetchCompletedAppReducer({ search: searchValue || '', id: 2 }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(fetchCompletedAppReducer({ search: searchValue || '', id: 2 }));
        }
    }, [searchValue]);

    const handleBuyNow = async (e: React.MouseEvent) => {
        e.stopPropagation();
        try {
            const sendData = {
                email: LoginData?.email,
                fullname: `${LoginData?.first_name} ${LoginData?.last_name}`,
                mobile: LoginData?.phone,
                state: LoginData?.state,
                city: LoginData?.city,
            }
            const result = await SsoMchemistLoginApi(sendData);
            if (result?.status) {
                window.open(`${MCHEMIST_REDIRECT}/?id=${result?.data?.id}&isAkos=true`)
            }
        } catch (error) {

        }
    }
    const handleViewDetails = (item: appointmentTypes) => {
        navigate('/MyAppointments/details', {
            state: {
                id: item?.appointmentId,
                item: item
            }
        })
    }

    const handleViewPrescription = (e: React.MouseEvent, item: appointmentTypes) => {
        e.stopPropagation();
        dispatch(handleViewDocument({
            dateTime: item?.dateTime,
            doctorName: item?.doctorName,
            experience: item?.experience,
            prescriptionURL: item?.prescriptionURL ? item?.prescriptionURL : '',
            speciality: item?.speciality
        }))
    }

    return (
        <div>
            {/* <div className={styles.CompletedBannerSec}>
                <div className="grid grid-cols-2 gap-6">
                    <section className={styles.bannerContentSec}>
                        <img src={logo} alt="mchemist logo" />
                        <h4>Upto <span>22% OFF</span> on <span>Medicines and Healthcare Products</span></h4>
                    </section>
                    <section className={styles.bgImgsec}>
                        <img src={bgImg} alt="bgImg" />
                    </section>
                </div>
            </div> */}
            <div className={styles.bannerSection}>
                <img src={Images.MedicineBanner} alt="care team banner" />
            </div>
            <div className={'md:w-1/2 w-full mb-6 mt-6'}>
                <FormGroup className="customFormGroup">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search by doctor or specialising in'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell>Doctor Name</TableCell>
                                    <TableCell align="right">Experience</TableCell>
                                    <TableCell align="right">Specialisation</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Date & Time</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Call duration</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                CompletedAppointment?.length > 0 ?
                                    <TableBody>
                                        {
                                            CompletedAppointment?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        onClick={() => handleViewDetails(item)}
                                                        className='custumTableRow cursor-pointer'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        title="View Details"
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.doctorName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {`${item?.experience !== '1' ? `${item?.experience} years` : `${item?.experience} year`}`}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.speciality}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.dateTime}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.callDuration}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.buyNowCompleteTable} onClick={(e) => handleBuyNow(e)}>
                                                                <p>Buy Now</p>
                                                                <PlusLogoIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button className={`outLineBtn ${styles.downloadPresBtn}`} onClick={(e) => handleViewPrescription(e, item)}>Download Prescription</button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }
            <DocumentViewModal />
        </div>
    );
}