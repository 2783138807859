import React, { FC, ReactNode } from 'react'
import styles from "../../assets/styles/layout.module.css"
interface Props {
    children: ReactNode,
    heightFull?: boolean,
    nopaddingY?: boolean,
}
const CustomContainer: FC<Props> = ({ children, heightFull, nopaddingY }) => {
    return (
        <div className={`${styles.CustomContainer} ${heightFull ? 'h-full' : ''} ${nopaddingY ? 'nopaddingY': ''}`}>
            {children}
        </div>
    )
}

export default CustomContainer