// export const BASE_URL = "https://patientportalapi.akosmd.in/api"; //development
export const BASE_URL = "https://patientportalapi-staging.akosmd.in/api"; //development
export const MCHEMIST_REDIRECT = "https://www.mchemist.com/basket/prescription"; //development

// for mchecmist SSO only
export const CLIENT_ID = 'b150e0b836bf6a04e1546671690f2899'; // live
export const SECRET_KEY = '605bcc5691ae77a84e635ffa63903587d9583fd1188f6e803f1bec3a1c664d4c';// live

// export const CLIENT_ID = "c234ef84dc19a87e44471a5214439758"; // staging
// export const SECRET_KEY =
//   "459a18ff2658675e37324525ca400fa685f8fd542408dddf7d37cd6a5a30eca5"; // staging

// for mchecmist SSO only

export const LOGIN = "/auth/login";
export const FORGOT_PASSWORD = "/forgot-password/send-otp";
export const VERIFY_OTP = "/forgot-password/verify-otp";
export const CHANGE_PASSWORD = "/forgot-password/change-password";
export const CHANGE_PASSWORD_INSIDE = "/forgot-password/change_password";
export const RESEND_OTP = "/resend-otp/send";
export const HELP_FAQ = "/help/faq";
export const HELP_SEND_EMAIL = "/help/send-email";
export const GET_ASSESSMENTS = "/assessments/get";
export const CREATE_BULK = "/assessments/createBulk";
export const GET_MY_CARETEAM = "/care-team/get";
export const GET_DOCTORS_LIST = "/doctors";
export const CARE_TEAM_CREATE = "/care-team/create";
export const BOOK_APPOINTMENT = "/appointment/book";
export const GET_MY_MEDICINE = "/appointment/my-medicines";
export const GET_APPOINTMENT = "/appointment/get";
export const RESCHEDULE_APPOINTMENT = "/appointment/reschedule";
export const CANCEL_APPOINTMENT = "/appointment/cancel";
export const APPOINTMENT_DETAILS = "/appointment/details";
export const GET_MEDICAL_RECORDS = "/appointment/medical-records";
export const GET_DASHBOARD = "/appointment/dashboard";
export const GET_TALKTO_DOCTOR = "/appointment/talkToDoctor";
export const GET_FOLDER = "/document/folders";
export const GET_FILES = "/document/files";
export const GET_PROFILE = "/patient/profile";
export const GET_MASTER = "/data/patient";
export const GET_STATE = "/data/states";
export const GET_CITY = "/data/cities";
export const GET_PACKAGE_AND_TEST = "/labs/packages-and-tests";
export const UPLOAD_PRESCRIPTION = "/labs/upload";
export const LABS_ADDTO_CART = "/labs/addToCart";
export const GET_CART_DETAILS = "/labs/cart/details";
export const REMOVE_CART_ITEM = "/labs/remove";
export const GET_LAB_CITIES = "/labs/cities";
export const LAB_LIST_BY_CITY = "/labs/cart/lab-details";
export const LAB_BRANCHES = "/labs/branches";
export const TEST_AND_PACKAGE_BYLAB = "/labs/tests-and-packages";
export const GET_LAB_PRESCRIPTION = "/labs/prescription";
export const DELETE_PRES_FILE = "/labs/prescription";
export const LAB_ORDER = "/labs/order";
export const GET_LAB_ORDERS = "/labs/orders";
export const GET_CARE_PLAN = "/care/plan";
export const GET_OPENTOK_ROOM_KEYS = "/getOpentokRoomKeys";
export const GENERATE_TOKEN = "/opentok/generate-token";
export const GENERATE_CALLID_BY_PATIENT = "/generateCallIdByPatientId";
export const CHECK_STATUS_WAITING_ROOM = "/checkstatusinwatingroom";
export const GET_SAVED_USER_ID = "/getSavedUserId";
export const ADD_USERTO_WAITING = "/addUserToWaiting";
export const CALL_END_BY_PATIENT = "/callendbypatient";
export const DISCONNECT_REASON = "/updatetcalldisconnectreason";
export const PATIENT_DETAILS_CHECK = "/patient/details";
export const UPDATE_PROFILE_ON_CALL = "/patient/updateProfile";
export const GET_BOOKED_LABTEST = "/labs/list";
export const GET_TESTOR_PACKAGE_DETAILS = "/labs/test-or-package-details";
export const FIX_LAB_ON_CART = "/labs/cart";
export const REMOVE_ALL_CART = "/labs/cartRemove";
export const LAB_DETAILSBY_CART_FOR_CHANGELAB = "/labs/labDetail";
export const PURCHASE_LABTEST = "/labs/purchaseLabTest";
export const CARE_NAVIGATOR_PATIENT_SIGNIN = "/patient/patientSignIn";
export const SAVE_NOTES_CARE_NAVIGATOR = "/patient/notes";
export const SSO_MCHEMIST_LOGIN = "/patient/singlesignin";
export const CHAT_LIST_USERS = "/chat/chat-list";
export const CHAT_HISTORY = "/chat/chat-history";
export const CHAT_UPDATE_READ_STATUS = "/chat/update-read-status";
export const GET_NOTIFICATION = "/notify/notifications";
export const REMOVE_NOTIFICATION = "/notify/removeNotification";
export const UPLOAD_FILE = "/data/upload";
export const GET_BEHAVIOURALHEALTH = "/assessments/behaviouralHealth";
export const GET_ASSESSMENTS_DETAILS = "/assessments/detail";
export const GET_ASSESSMENTS_COMPLETED = "/assessments/completed";
export const GET_ASSESSMENTS_RESULT = "/assessments/completedDetails";
export const LAB_PAYMENT_SUCCESS = "/labs/paymentStatus";
export const GET_SLOTS = "/doctors/slot";
export const UPDATE_LAB_HOME_ADDRESS = "/labs/updateAddress";
export const UPDATE_READ_NOTIFICATION = "/notify/updateReadNotification";
export const GET_LAB_HOME_ADDRESS = "/labs/getLabTestAddress";