import React from 'react';
import QRCode from 'qrcode.react';
import styles from '../../assets/styles/careplan.module.css';

const QrCodeComponent: React.FC = () => {
  const qrUrl = "https://360.akosmd.in";

  return (
    <div className={styles.qrCode}>
      <QRCode value={qrUrl}  />
    </div>
  );
};

export default QrCodeComponent;
