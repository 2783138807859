import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import styles from "../../assets/styles/rpmmanagement.module.css"

interface DataPoint {
    date: string;
    systolic: number;
    diastolic: number;
}

interface RPMDetailsChartProps {
    data: DataPoint[];
}

const RPMDetailsChart: React.FC<RPMDetailsChartProps> = ({ data }) => {
    return (
        <div>
            <div className={styles.sysDyaMain}>
                <div className={styles.sysContent}>
                    <h4>SYS</h4>
                    <p>120</p>
                </div>
                <div className={styles.sysContent}>
                    <h4>DYA</h4>
                    <p>78</p>
                </div>
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="systolic" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="diastolic" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </div>

    );
}

export default RPMDetailsChart;
