import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/careplan.module.css"
import PlanCard from '../../components/careplan/PlanCard';
import { handleViewDocument } from '../../utils/store/common/CommonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import DocumentViewModal from '../../components/savedDocuments/DocumentViewModal';
import { getCarePlanApi } from '../../utils/api/services';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { toTitleCase } from '../../utils/functions/common';
import Loader from '../../components/common/Loader';
import PatientCard from '../../components/careplan/PatientCard';

const CarePlan = () => {
    const [planData, setPlanData] = useState<CarePlan | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { LoginData } = useSelector((state: RootState) => state.auth)

    const handleViewPrescription = () => {
        dispatch(handleViewDocument({
            prescriptionURL: 'https://pdfobject.com/pdf/sample.pdf',
            fileName: 'fileName'
        }))
    }

    const fetchCarePlan = async (data: { companyId: number, patientId: number }) => {
        try {
            setLoading(true)
            const result = await getCarePlanApi(data);
            if (result?.success) {
                setLoading(false)
                setPlanData(result?.carePlans)
            }
            else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);

        }
    }
    useEffect(() => {
        if (LoginData) {
            fetchCarePlan({ companyId: LoginData?.companyId, patientId: LoginData?.loggedPatientId });
        }
    }, [])

    if (loading) {
        return <Loader />
    }

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Care Plan</h2>
                </div>
                <div className={styles.main}>
                    <div className={styles.downloadAndWelcome}>
                        <div className={styles.welcome}>
                            <h3>Welcome !</h3>
                            <p>{planData?.planName}</p>
                        </div>
                        {/* <div className={styles.download}>
                            <button className='fillButton whiteBgBtn' onClick={handleViewPrescription}>Download Care Card</button>
                        </div> */}
                    </div>
                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-y-6 xl:gap-x-16 mt-4'>
                        <section className={styles.left}>
                            <div className={styles.benefit}>
                                <h4>Benefits</h4>
                            </div>
                            <ul className={styles.benefitList}>
                                {
                                    planData?.planBenefits?.map((item, index) => {
                                        return (
                                            <li key={index}>{item}</li>
                                        )
                                    })
                                }
                            </ul>
                            <div className={'grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 md:gap-6 gap-y-6 mt-6'}>
                                {planData?.planDetails &&
                                    Object.entries(planData?.planDetails).map(([key, value], index) => {
                                        const title = toTitleCase(key);
                                        const ConvertedData: CardData = {
                                            id: index,
                                            title,
                                            value: `${value.remaining ? value.remaining + '/' : ''}${value.total}`,
                                            subValue: '',
                                            frequency: value.planType,
                                        };

                                        return <PlanCard key={index} data={ConvertedData} />;
                                    })}
                            </div>
                        </section>
                        <section>
                            {
                                planData?.patientDetails &&
                                <PatientCard data={planData?.patientDetails} validFrom={planData?.validFrom} validUpto={planData?.validUpto} />
                            }
                        </section>
                    </div>
                </div>
                <div className={styles.contactAdminText}>
                    <p>Please contact your admin for upgrading your plan or any details <span>**</span></p>
                </div>
            </div>
            <DocumentViewModal />
        </CustomContainer>
    )
}

export default CarePlan