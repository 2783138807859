import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import userIcon from "../../assets/images/team/1.png"
import styles from "../../assets/styles/appointments.module.css"
import { PlusLogoIcon } from '../../utils/functions/Icons';
import { FC } from 'react';
import NoDataFound from '../common/NoDataFound';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { handleViewDocument } from '../../utils/store/common/CommonSlice';
import { SsoMchemistLoginApi } from '../../utils/api/services';
import { MCHEMIST_REDIRECT } from '../../utils/api/routes';

interface Props {
    AllMedicineData: medicineTypes[],
}
const MYMedicineTable: FC<Props> = ({ AllMedicineData }) => {
    const dispatch = useDispatch<AppDispatch>()
    const { isCareNavigator, LoginData } = useSelector((state: RootState) => state.auth)
    const handleBuyNow = async () => {
        try {
            const sendData = {
                email: LoginData?.email,
                fullname: `${LoginData?.first_name} ${LoginData?.last_name}`,
                mobile: LoginData?.phone,
                state: LoginData?.state,
                city: LoginData?.city,
            }
            const result = await SsoMchemistLoginApi(sendData);
            if(result?.status){
                window.open(`${MCHEMIST_REDIRECT}/?id=${result?.data?.id}&isAkos=true`)
            }
        } catch (error) {

        }
    }

    const handleViewPrescription = (item: medicineTypes) => {
        dispatch(handleViewDocument({
            dateTime: item?.dateTime,
            doctorName: item?.doctorName,
            experience: item?.experience,
            prescriptionURL: item?.prescriptionURL,
            speciality: item?.speciality
        }))
    }
    return (
        <TableContainer component={Paper} className='TableContainer'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='custumTableHead'>
                    <TableRow className={styles.completeTableRow}>
                        <TableCell>Doctor Name</TableCell>
                        <TableCell align="right">Experience</TableCell>
                        <TableCell align="right">Specialisation</TableCell>
                        <TableCell align="right">Prescribed on</TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                {
                    AllMedicineData?.length > 0 ?
                        <TableBody>
                            {
                                AllMedicineData?.map((item, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className={styles.doctorSec}>
                                                    <img src={item?.profilePic ? item?.profilePic : userIcon} alt="user icon" />
                                                    {item?.doctorName}
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item?.experience} years
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item?.speciality}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <p>{item?.dateTime}</p>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <button disabled={isCareNavigator ? true : false} className={styles.buyNowCompleteTable} onClick={handleBuyNow}>
                                                    <p>Buy Now</p>
                                                    <PlusLogoIcon />
                                                </button>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <button className={`outLineBtn ${styles.downloadPresBtn}`} onClick={() => handleViewPrescription(item)}>Download Prescription</button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                        :
                        <TableBody>
                            <TableRow
                                className='custumTableRow'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" colSpan={6} className='flex justify-center items-center w-full'>
                                    <NoDataFound />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                }
            </Table>
        </TableContainer>
    )
}

export default MYMedicineTable