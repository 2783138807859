import { Box, Modal } from '@mui/material'
import React, { FC, useState } from 'react'
import { CrossIcon } from '../../utils/functions/Icons'
import Packages from './Packages'
import Tests from './Tests'
import styles from "../../assets/styles/labtest.module.css"
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import Loader from '../common/Loader'

interface Props {
    openAddMore: boolean,
    closeAddMore: () => void;
    selectedLab: number | null,
    SelectedLabName: string,
    fetchLabDetailsForChangeLab: (id: number) => void
}
const AddMoreLabTest: FC<Props> = ({ openAddMore, closeAddMore, selectedLab, SelectedLabName, fetchLabDetailsForChangeLab }) => {
    const { loading, PackagesAndTestDataByLab } = useSelector((state: RootState) => state.labtest)
    if (loading) {
        return <Loader />
    }
    return (
        <Modal
            open={openAddMore}
            onClose={closeAddMore}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 1000 }} className={"ModalBoxStyle"}>
                <div className={`modalHeader`}>
                    {SelectedLabName && <h3>Add more test from {SelectedLabName}</h3>}
                    <CrossIcon onClick={closeAddMore} />
                </div>
                <div className='ModalContentSection'>
                    <section className={styles.packageSections}>
                        <Packages
                            data={PackagesAndTestDataByLab.categoryPackages}
                            addMore={true}
                            closeAddMore={closeAddMore}
                            selectedLab={selectedLab}
                            fetchLabDetailsForChangeLab={fetchLabDetailsForChangeLab}
                        />
                        <Tests
                            data={PackagesAndTestDataByLab.categoryTests}
                            addMore={true}
                            closeAddMore={closeAddMore}
                            selectedLab={selectedLab}
                            fetchLabDetailsForChangeLab={fetchLabDetailsForChangeLab}
                        />
                    </section>
                </div>
            </Box>
        </Modal>
    )
}

export default AddMoreLabTest