import { Images } from "./Images"

export type dataTypes = {
    id: number,
    label: string,
    color: string,
    position: {
        top: string,
        left: string
    }
}
export const RiskData: dataTypes[] = [
    { id: 1, label: 'Eye care', color: '#F9813E', position: { top: '7%', left: '53%' } },
    { id: 2, label: 'Heart care', color: '#AA2626', position: { top: '23%', left: '56%' } },
    { id: 3, label: 'Liver care', color: '#54A276', position: { top: '31%', left: '42%' } },
    { id: 4, label: 'Bone care', color: '#AA2626', position: { top: '71%', left: '43%' } },
];

