import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./auth/AuthSlice";
import CommonSlice from "./common/CommonSlice";
import CareTeamSlice from "./careteam/CareTeamSlice";
import MyMedicineSlice from "./mymedicine/MyMedicineSlice";
import AppointmentSlice from "./appointment/AppointmentSlice";
import ProfileSlice from "./profile/ProfileSlice";
import LabTestSlice from "./labtest/LabTestSlice";

const AllReducers = combineReducers({
  auth: AuthSlice.reducer,
  common: CommonSlice.reducer,
  careteam: CareTeamSlice.reducer,
  mymedicine: MyMedicineSlice.reducer,
  appointment: AppointmentSlice.reducer,
  profile: ProfileSlice.reducer,
  labtest: LabTestSlice.reducer,
});

export default AllReducers;
