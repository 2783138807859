import { Tooltip } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import bodyImg from "../../assets/images/common/body.png";
import styles from "../../assets/styles/dashboard.module.css"
import { RiskData, dataTypes } from '../../utils/functions/Data';


const blink = keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.3);
  }
`;

const advancedBlink = (color: string) => keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 0 0 ${color};
  }
  50% {
    transform: translate(-50%, -50%) scale(1.3);
    box-shadow: 0 0 10px 1px ${color};
  }
`;

interface CircleProps {
    color: string;
}

const Circle = styled('div')<CircleProps>(({ color }) => ({
    backgroundColor: color,
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    transition: 'transform 0.3s ease-in-out',
    animation: `${advancedBlink(color)} 1.5s infinite`,
    '&:hover': {
        animation: `${blink} 1s infinite`,
    },
}));

const PointContainer = styled('div')({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
});

const RiskMap = () => {
    const renderTooltip= (point: dataTypes) => {
        return (
            <div className={styles.tooltipMain}>
                <div className={styles.tooltipHead}>
                    <h3>{point?.label}</h3>
                    <h3>72 /per min</h3>
                </div>
                <div className={styles.tooltipcontent}>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vitae ipsum sed velit efficitur tincidunt. Nullam posuere justo nec nunc feugiat, eget vestibulum purus fringilla. Quisque bibendum augue ac justo fermentum, nec </p>
                </div>
            </div>
        )
    }
    return (
        <div className="relative RiskMapMain">
            <img src={bodyImg} alt="Body" className="h-full relative w-full" />
            {RiskData.map(point => (
                <Tooltip
                    key={point.id}
                    title={renderTooltip(point)}
                    arrow
                    placement="right"
                >
                    <PointContainer style={{ top: point.position.top, left: point.position.left }}>
                        <Circle color={point.color} />
                    </PointContainer>
                </Tooltip>
            ))}
        </div>
    );
};

export default RiskMap;
