import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { FC } from 'react'
import { PlusLogoIcon } from '../../utils/functions/Icons'
import styles from "../../assets/styles/medicalrecord.module.css"
import userIcon from "../../assets/images/team/1.png"
import { useNavigate } from 'react-router-dom'
import { Images } from '../../utils/functions/Images'

interface propTypes {
    appointmentsData: appointmentTypes[]
}
const DoctorAppointmentTable: FC<propTypes> = ({ appointmentsData }) => {
    const navigate = useNavigate();

    const handleViewDetails = (e: React.MouseEvent, item: appointmentTypes) => {
        e.stopPropagation();
        navigate('/MyAppointments/details', {
            state: {
                id: item?.appointmentId,
                item: item
            }
        })
    }

    return (
        <TableContainer component={Paper} className='TableContainer'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='custumTableHead'>
                    <TableRow className={styles.completeTableRow}>
                        <TableCell>Doctor Name</TableCell>
                        <TableCell align="right">Specialisation</TableCell>
                        <TableCell align="right" className='whitespace-nowrap'>Date & Time</TableCell>
                        <TableCell align="right" className='whitespace-nowrap'>Type of call</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        appointmentsData?.map((item, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    className={`custumTableRow ${item?.status === 'Completed' ? 'cursor-pointer' : ''}`}
                                    title={item?.status === 'Completed' ? "View Details" : ''}
                                    onClick={(e) => item?.status === 'Completed' && handleViewDetails(e, item)}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <div className={styles.doctorSec}>
                                            <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                            {item?.doctorName}
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item?.speciality}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <p>{item?.dateTime}</p>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <button className={`${styles.StatusBtn} ${styles.StatusBtnUpcoming}`}>{item?.typeofcall}</button>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <button className={`${styles.StatusBtn} ${item?.status === 'Cancelled' ? styles.StatusBtnCancel : item?.status === 'Upcoming' ? styles.StatusBtnUpcoming : ''}`}>{item?.status}</button>
                                    </TableCell>

                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DoctorAppointmentTable