import { Box, FormGroup, FormLabel, Modal, TextField } from '@mui/material'
import React from 'react'
import { CrossIcon } from '../../utils/functions/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { handleCloseModal } from '../../utils/store/profile/ProfileSlice'
import styles from "../../assets/styles/profile.module.css"
import { handleKeyPress } from '../../utils/functions/common'
const GenerateAbhaId: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { openModal, generateAbha } = useSelector((state: RootState) => state.profile)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
    }
    return (
        <Modal
            open={openModal}
            onClose={() => dispatch(handleCloseModal())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 400 }} className={"ModalBoxStyle"}>
                <div className="modalHeader">
                    <h3>Create your ABHA ID</h3>
                    <CrossIcon onClick={() => dispatch(handleCloseModal())} />
                </div>
                <div className='ModalContentSection'>
                    <div className={styles.generateAbhaMain}>
                        <form noValidate={false}>
                            <div className='grid grid-cols-1 gap-y-6'>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel flex gap-2">Enter your Aadhaar Number</FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="aadharcard"
                                        placeholder='**** **** ****'
                                        value={generateAbha?.aadharcard}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 12,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel flex gap-2">Phone number for ABHA communications</FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="number"
                                        placeholder='Phone number for ABHA communications'
                                        value={generateAbha?.number}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 10,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className='mt-8'>
                                <button className='fillButton w-full'>Generate ABHA ID</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default GenerateAbhaId