import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import styles from "../../assets/styles/medicalrecord.module.css"
import userIcon from "../../assets/images/team/1.png"
import { FC } from 'react'
interface PropTypes {
    medicinePrescribed: MedicinePrescribed[]
}
const MedicineListTable: FC<PropTypes> = ({ medicinePrescribed }) => {
    return (
        <TableContainer component={Paper} className='TableContainer'>
            <Table sx={{ minWidth: 650 }}>
                <TableHead className='custumTableHead medicineListTable'>
                    <TableRow>
                        <TableCell>Medicine Name</TableCell>
                        <TableCell align="right">Drug Form</TableCell>
                        <TableCell align="right">Strength</TableCell>
                        <TableCell align="right">Frequency</TableCell>
                        <TableCell align="right">Duration</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        medicinePrescribed.map((item, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    className='custumTableRow medicineListTableRow'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {item?.medicineName}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item?.drugForm}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item?.strength}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item?.frequency}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item?.duration}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MedicineListTable