import { Box, FormGroup, FormLabel, Modal, TextareaAutosize } from '@mui/material';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { CrossIcon, CrossWhiteIcon } from '../../utils/functions/Icons';
import InputFile from '../common/InputFile';
import styles from "../../assets/styles/labtest.module.css"
import { Images } from '../../utils/functions/Images';

interface propTypes {
    isPresModalOpen: boolean,
    ClosePresModal: () => void;
    handleFileChange: (name: string, file: File | null) => void;
    PrescriptionState: PrescriptionStates;
    handleNotesChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    submitPrescription: (e: FormEvent<HTMLFormElement>) => void;
    prescriptionList: PrescriptionRes[] | [];
    fileTypePres: string,
    HandleSelectedPres: (file: string) => void;
    handleDeletePre: (e:React.MouseEvent, id: number) => void
}

const UploadPrescription: FC<propTypes> = ({
    isPresModalOpen,
    ClosePresModal,
    handleFileChange,
    PrescriptionState,
    handleNotesChange,
    submitPrescription,
    prescriptionList,
    fileTypePres,
    HandleSelectedPres,
    handleDeletePre
}) => {

    return (
        <Modal
            open={isPresModalOpen}
            onClose={ClosePresModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 800 }} className={"ModalBoxStyle"}>
                <div className="modalHeader">
                    <h3>Upload Prescription</h3>
                    <CrossIcon onClick={ClosePresModal} />
                </div>
                <div className='ModalContentSection'>
                    <form noValidate={false} onSubmit={submitPrescription}>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                            <div className={styles.uploadPreSec}>
                                <div className={`${styles.selectedPreBox} ${PrescriptionState?.prescriptionFile ? 'cursor-pointer' : ''}`} onClick={() => PrescriptionState?.prescriptionFile && window.open(PrescriptionState?.prescriptionFile)}>
                                    <img src={Images.pdfIcon} alt="pdf Icon" />
                                </div>
                                <ul className={`grid grid-cols-5 gap-4 ${styles.presListMain}`}>
                                    {
                                        prescriptionList?.map((item, index) => {
                                            const typeImg = item?.prescriptionFile?.split('.')?.pop()?.toLowerCase();
                                            return (
                                                <li className={PrescriptionState?.prescriptionFile === item?.prescriptionFile ? styles.selectList : ''} key={index} onClick={() => HandleSelectedPres(item?.prescriptionFile)}>
                                                    <span onClick={(e) => handleDeletePre(e, item?.id)}><CrossWhiteIcon /></span>
                                                    <img src={Images.pdfIcon} alt="pdf icon" />
                                                </li>
                                            )
                                        })
                                    }

                                </ul>
                                <div className='w-1/2'>
                                    <FormGroup className="customFormGroup">
                                        <FormLabel className="customFormLabel">Upload New Prescription</FormLabel>
                                        <InputFile
                                            title={PrescriptionState?.prescriptionFile ? 'Selected' : 'Choose File'}
                                            colorBorder={true}
                                            id={"profile_image"}
                                            name={"profile_image"}
                                            onlyImg={false}
                                            handleFileChange={handleFileChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className={styles.NotesPreSec}>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Notes</FormLabel>
                                    <TextareaAutosize
                                        minRows={2}
                                        className={`custumTextField profileInput`}
                                        name='notes'
                                        value={PrescriptionState?.notes}
                                        onChange={handleNotesChange}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <button className='fillButton'>Save</button>
                        </div>
                    </form>
                </div>
            </Box>
        </Modal>
    )
}

export default UploadPrescription