import React, { FC } from 'react'
import styles from "../../assets/styles/assessments.module.css"
interface Props {
    title?: string,
    btnText?: string,
    assessmentName: string
    assessmentImage?: string,
    id: number,
    handleStartNow: (id: number, assessmentName: string, totalLevels: number) => void;
    handleCheckResult?: (id: number, assessmentName: string, totalLevels: number) => void;
    totalLevels: number,
    taken?: boolean
}
const AssessmentCard: FC<Props> = ({
    btnText,
    assessmentName,
    assessmentImage,
    handleStartNow,
    id,
    totalLevels,
    handleCheckResult,
    taken
}) => {
    return (
        <div className={styles.AssessmentCard}>
            <div className={styles.cardHead}>
                <h3>{assessmentName}</h3>
            </div>
            <div className={styles.imgAndContent}>
                <div className={styles.assessmentsImg}>
                    <img src={assessmentImage} alt="assessments image" />
                </div>
                <div className={styles.assessmentsContent}>
                    <button className='outLineBtn' onClick={() => (taken === true && handleCheckResult) ? handleCheckResult(id, assessmentName, totalLevels) : handleStartNow(id, assessmentName, totalLevels)}>{btnText}</button>
                </div>
            </div>
        </div>
    )
}

export default AssessmentCard