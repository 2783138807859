import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StateTypes {
  sidebarToggle: boolean;
  particularPrescription: viewPrescriptionTypes | null;
  viewDocument: boolean;
}
const initialState: StateTypes = {
  sidebarToggle: false,
  particularPrescription: null,
  viewDocument: false,
};
const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    handleToggle: {
      reducer: (state, action: PayloadAction<boolean | undefined>) => {
        state.sidebarToggle = action.payload !== undefined ? action.payload : !state.sidebarToggle;
      },
      prepare: (payload?: boolean) => ({ payload }),
    },
    handleCloseDocument: (state) => {
      state.viewDocument = false;
    },
    handleViewDocument: (
      state,
      action: PayloadAction<StateTypes["particularPrescription"]>
    ) => {
      state.particularPrescription = action.payload ?? null;
      state.viewDocument = true;
    },
  },
});

export const { handleToggle, handleViewDocument, handleCloseDocument } =
  CommonSlice.actions;
export default CommonSlice;
