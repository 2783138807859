import React, { useState } from "react";

const PO60Connect = () => {
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [error, setError] = useState(null);
  const [measurementData, setMeasurementData] = useState([]);
  const PO60_SERVICE_UUID = "0000ff12-0000-1000-8000-00805f9b34fb"; 
  const PO60_WRITE_CHARACTERISTIC_UUID = "0000ff01-0000-1000-8000-00805f9b34fb"; 
  const PO60_NOTIFY_CHARACTERISTIC_UUID = "0000ff02-0000-1000-8000-00805f9b34fb"; 

  const connectDevice = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ name: "PO60" }],
        optionalServices: [PO60_SERVICE_UUID],
      });

      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(PO60_SERVICE_UUID);
      const notifyCharacteristic = await service.getCharacteristic(PO60_NOTIFY_CHARACTERISTIC_UUID);
      notifyCharacteristic.startNotifications();
      notifyCharacteristic.addEventListener("characteristicvaluechanged", handleNotifications);
      setDeviceInfo({ name: device.name, id: device.id });
      setError(null);

      await requestMeasurementData(service);
    } catch (err) {
      console.error(err);
      setError("Failed to connect to the PO60 device.");
    }
  };

  const requestMeasurementData = async (service) => {
    try {
      const writeCharacteristic = await service.getCharacteristic(PO60_WRITE_CHARACTERISTIC_UUID);
      const command = new Uint8Array([0x99, 0x00, 0x19]);
      await writeCharacteristic.writeValue(command);
      console.log("Measurement data request sent.");
    } catch (err) {
      console.error("Error sending measurement data request:", err);
    }
  };

  const handleNotifications = (event) => {
    const value = event.target.value;
    
    console.log('Raw notification value:', value);

    let rawData = [];
    for (let i = 0; i < value.byteLength; i++) {
      rawData.push(value.getUint8(i));
    }
    console.log('Raw byte data:', rawData);

    if (value.byteLength >= 6) {
      const spO2Max = value.getUint8(0);
      const spO2Min = value.getUint8(1);
      const spO2Avg = value.getUint8(2);
      const prMax = value.getUint8(3);
      const prMin = value.getUint8(4);
      const prAvg = value.getUint8(5);

      console.log('Parsed SpO2 Max:', spO2Max, 'SpO2 Min:', spO2Min, 'SpO2 Avg:', spO2Avg);
      console.log('Parsed PR Max:', prMax, 'PR Min:', prMin, 'PR Avg:', prAvg);

      setMeasurementData((prevData) => [
        ...prevData,
        { spO2Max, spO2Min, spO2Avg, prMax, prMin, prAvg }
      ]);
    } else {
      console.log('Notification does not contain enough data.');
    }
  };



  return (
    <div style={styles.container}>
      <h1 style={styles.title}>PO60 Device Connection</h1>

      {deviceInfo ? (
        <div style={styles.deviceInfo}>
          <h2>Connected Device:</h2>
          <p><strong>Name:</strong> {deviceInfo.name}</p>
          <p><strong>ID:</strong> {deviceInfo.id}</p>
        </div>
      ) : (
        <button style={styles.connectBtn} onClick={connectDevice}>
          Connect to PO60
        </button>
      )}

      {error && <p style={styles.error}>{error}</p>}

      <div style={styles.measurementList}>
        <h2>Measurement Data</h2>
        {measurementData.length > 0 ? (
          <ul>
            {measurementData.map((data, index) => (
              <li key={index} style={{
                ...styles.measurementItem,
                ...(index % 2 === 0 ? {} : styles.measurementItemAlt),
              }}>
                <p><strong>SpO2 Max:</strong> {data.spO2Max}%</p>
                <p><strong>SpO2 Min:</strong> {data.spO2Min}%</p>
                <p><strong>SpO2 Avg:</strong> {data.spO2Avg}%</p>
                <p><strong>PR Max:</strong> {data.prMax} bpm</p>
                <p><strong>PR Min:</strong> {data.prMin} bpm</p>
                <p><strong>PR Avg:</strong> {data.prAvg} bpm</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No measurement data yet.</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '2px solid #ddd',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    color: '#333',
  },
  deviceInfo: {
    margin: '20px 0',
    padding: '10px',
    backgroundColor: '#e7f3ff',
    borderLeft: '5px solid #2196f3',
  },
  connectBtn: {
    display: 'block',
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#4caf50',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  measurementList: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  measurementItem: {
    padding: '8px',
    backgroundColor: '#f9f9f9',
    marginBottom: '8px',
    borderBottom: '1px solid #ccc',
  },
  measurementItemAlt: {
    backgroundColor: '#eef7f5',
  },
};
export default PO60Connect;
