import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../assets/styles/layout.module.css";
import { InboxIcon, NotificationIcon, ProfileIcon, ToggleIcon } from "../../utils/functions/Icons";
import CustomContainer from "../common/CustomContainer";
import NotificationBox from "../common/NotificationBox";
import { clearAuth } from "../../utils/store/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleToggle } from "../../utils/store/common/CommonSlice";
import { RootState } from "../../utils/store";
import useSocket from "../../utils/functions/useSocket";
import { toast } from "react-toastify";
import { updateReadNotificationApi } from "../../utils/api/services";

const SidebardHeader: React.FC = () => {
    const [activeNav, setActiveNav] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const headerRef = useRef<HTMLDivElement>(null);
    const notificationButtonRef = useRef<HTMLDivElement>(null);
    const notificationBoxRef = useRef<HTMLDivElement>(null);
    const { connect, disconnect, sendMessage } = useSocket();

    useEffect(() => {
        connect();
        return () => {
            disconnect();
        };
    }, [connect, disconnect]);

    const handleAccount = useCallback(() => {
        setActiveNav((prev) => !prev);
    }, []);
    const handleNotification = useCallback(() => {
        setShowNotification((prev) => !prev);
        handleUpdateReadStatus();
    }, []);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (
            headerRef.current && !headerRef.current.contains(event.target as Node) &&
            !(document.querySelector('.DoNotCloseNotification')?.contains(event.target as Node))
        ) {
            setActiveNav(false);
        }
        if (
            notificationButtonRef.current && !notificationButtonRef.current.contains(event.target as Node) &&
            notificationBoxRef.current && !notificationBoxRef.current.contains(event.target as Node) &&
            !(document.querySelector('.DoNotCloseNotification')?.contains(event.target as Node))
        ) {
            setShowNotification(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const logout = () => {
        dispatch(clearAuth());
        navigate("/login");
    };


    const fetchNotification = async (data: {
        role: string;
        referenceId: number;
        filter: string;
    }) => {
        try {
            const result = await sendMessage('getNotifications', data);
            console.log(result, "result")
            if (result?.success) {
                setNotificationCount(result?.notifications?.totalCount)
            }
        } catch (error: any) {
        }
    }
    useEffect(() => {
        if (LoginData) {
            fetchNotification({ role: LoginData?.role, referenceId: LoginData?.loggedPatientId, filter: 'all' });
        }
    }, [sendMessage]);

    const handleUpdateReadStatus = async () => {
        try {
            const sendData = {
                role: LoginData?.role,
                referenceId: LoginData?.loggedPatientId,
            }
            const result = await updateReadNotificationApi(sendData);
            if (result.success && LoginData) {
                fetchNotification({ role: LoginData?.role, referenceId: LoginData?.loggedPatientId, filter: 'all' });
            }
        } catch (error) {

        }
    }

    const activeNotification = location.pathname.startsWith('/notification');
    const activeInbox = location.pathname.startsWith('/inbox');
    const activeMyaccount = location.pathname.startsWith('/myaccount') || activeNav;

    return (
        <header className={styles.headerSection}>
            <div className={styles.toogleBtnMain} onClick={() => dispatch(handleToggle())}>
                <ToggleIcon />
            </div>
            <CustomContainer nopaddingY={true}>
                <div className={styles.HeaderMain}>
                    <section className={styles.hRightSection}>
                        <div
                            className={`${styles.HRTextIcon} ${showNotification ? styles.ActiveHRTextIcon : ''}`}
                            onClick={handleNotification}
                            ref={notificationButtonRef}
                        >
                            <NotificationIcon />
                            <h3>Notification     {
                                notificationCount !== 0 &&
                                <span className={styles.notificationCount}>({notificationCount})</span>
                            }</h3>
                            {showNotification && (
                                <div
                                    className={`${styles.HRShowHover} right-0`}
                                    ref={notificationBoxRef}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <NotificationBox />
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.HRTextIcon} ${activeInbox ? styles.ActiveHRTextIcon : ''}`}
                            onClick={() => navigate('/inbox')}
                        >
                            <InboxIcon />
                            <h3>Inbox</h3>
                        </div>
                        <div
                            className={`${styles.HRTextIcon} ${activeMyaccount ? styles.ActiveHRTextIcon : ''}`}
                            onClick={handleAccount}
                            ref={headerRef}
                        >
                            {
                                LoginData?.profilePic ? <img className="h-8" src={LoginData?.profilePic} alt="user" />
                                    :
                                    <ProfileIcon />
                            }
                            <h3>My account</h3>
                            {activeNav && (
                                <div className={styles.HRShowHover}>
                                    <div className={styles.HRListMain}>
                                        <li onClick={() => navigate('/myaccount/profile')}>My Profile</li>
                                        <li onClick={() => navigate('/myaccount/settings')}>Settings</li>
                                        <li onClick={logout}>Logout</li>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>
            </CustomContainer>
        </header>
    );
};

export default SidebardHeader;
