import React, { useEffect } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/labtest.module.css"
import LabTestTable from '../../components/medicalRecord/LabTestTable'
import { getBookedLabTestReducer } from '../../utils/store/labtest/LabTestSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import Loader from '../../components/common/Loader'
import { Images } from '../../utils/functions/Images'

const LabTests = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { loading } = useSelector((state: RootState) => state.labtest)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const { BookedLabData } = useSelector((state: RootState) => state.labtest)

    const handleBookLabTest = () => {
        if (BookedLabData?.cartCount && BookedLabData?.cartCount > 0) {
            navigate('/LabTests/Cart', {
                state: {
                    cartId: BookedLabData?.cartId
                }
            })
        }
        else {
            navigate('/LabTests/Book')
        }
    }
    useEffect(() => {
        if (LoginData?.loggedPatientId) {
            dispatch(getBookedLabTestReducer({ id: LoginData?.loggedPatientId }))
        }
    }, []);


    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Lab Tests</h2>
                    <button className='fillButton' onClick={handleBookLabTest}>Book Lab Tests</button>
                </div>
                <div className={styles.bannerSection}>
                <img src={Images.LabtestBanner} alt="labtest banner" />
                </div>
                {
                    loading ? <Loader />
                        :
                        <div className={styles.labTestMain}>
                            <LabTestTable BookedLabData={BookedLabData?.labTestList} />
                        </div>
                }
            </div>
        </CustomContainer>
    )
}

export default LabTests