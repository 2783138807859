import styles from "../../assets/styles/loader.module.css";

const Loader = () => {
    return (
        <div className={styles.loaderMain}>
            <div className={styles.loaderAll}></div>
        </div>
    )
}

export default Loader