import { useLocation, useNavigate } from 'react-router-dom';
import CustomContainer from '../../components/common/CustomContainer';
import { BackIcon } from '../../utils/functions/Icons';
import styles from "../../assets/styles/behaviouralhealth.module.css";
import { Box, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { createBulkBehaviouralApi, getBehaviouralHealthDetailsApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import NoDataFound from '../../components/common/NoDataFound';
import ProgressBar from '@ramonak/react-progress-bar';

const BehaviouralHealthDetails = () => {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<Record<string, string>>({});
  const [questionData, setQuestionData] = useState<QuestionTypes[] | []>([]);
  const [resultData, setResultData] = useState<assessmentResponse | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const submissionData = questionData
        .map((question) => {
          const selectedOption = question.options.find(
            (option) => option.optionsValue === formState[question.paramName]
          );
          return selectedOption ? {
            assessmentId: location?.state?.id,
            assessmentQuestionId: question.questionId,
            assessmentOptionId: selectedOption.optionId,
            patientId: 1,
          } : null;
        })
        .filter((item) => item !== null);
      const result = await createBulkBehaviouralApi(submissionData, { type: 2 });
      if (result?.success) {
        setLoading(false)
        toast.success(result?.message);
        setResultData(result?.assessmentResponse);
      }
      else {
        setResultData(null);
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.data?.errors || error?.message);
    }
  };

  const handleInputChange = (paramName: string, value: string) => {
    setFormState((prev) => ({
      ...prev,
      [paramName]: value,
    }));
  };

  const fetchAssessmentDetailsData = async (data: { id: number, type: number }) => {
    try {
      setLoading(true);
      const result = await getBehaviouralHealthDetailsApi(data);
      if (result?.success) {
        setLoading(false);
        setQuestionData(result?.questions);
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  };

  useEffect(() => {
    if (location.state?.id) {
      fetchAssessmentDetailsData({
        id: location.state?.id,
        type: 2,
      });
    }
  }, [location.pathname]);

  if (loading) {
    return <Loader />;
  }

  return (
    <CustomContainer>
      <div className='AllPageMain'>
        <div className='HeadAndButton'>
          <h2 className='flex items-center gap-4'>
            <BackIcon onClick={() => navigate(-1)} />
            {location?.state.assessmentName}
          </h2>
        </div>
        <div className={styles.detailsMain}>
          {questionData?.length > 0 ? (
            <form noValidate={false} onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-y-6 gap-x-16">
                {questionData.map((item, index) => (
                  item.optionType === 'radio' && (
                    <FormGroup className="customFormGroup" key={index}>
                      <FormLabel className="customFormLabel">
                        {item?.questionText} {item?.isRequired && <span className="errorMsg">*</span>}
                      </FormLabel>
                      <RadioGroup
                        row
                        className="comkonTestsRadioGroup"
                        name={item?.paramName}
                        value={formState[item?.paramName] || ''}
                        onChange={(e) => handleInputChange(item.paramName, e.target.value)}
                      >
                        {item.options.map((option) => (
                          <FormControlLabel
                            key={option.optionId}
                            value={option.optionsValue}
                            control={<Radio className="customRadio" required={item?.isRequired === 1 ? true : false} />}
                            label={option.optionText}
                            className="profileRadioLabel comkonTestsRadioLabel hideAsterisk"
                          />
                        ))}
                      </RadioGroup>
                    </FormGroup>
                  )
                ))}
              </div>
              <div className="flex justify-center items-center mt-8">
                <button className="fillButton" type="submit">
                  Check Results
                </button>
              </div>
              {resultData && (
                <div className="mt-8">
                  <div className={styles.progressBarMain}>
                    <div className={styles.resultHead}>
                      <h3>Results</h3>
                    </div>
                    <div className='progressbarResult'>
                      <ProgressBar
                        completed={resultData?.totalScore}
                        maxCompleted={resultData?.maxRange}
                        bgColor='linear-gradient(90deg, #1FBA5D 0%, #CFDF1A 15.64%, #FFD600 24.6%, #F90 33.8%, #FF6B00 48.67%, #EA0808 100%)'
                        baseBgColor='#D9D9D9'
                        ariaValuemin={0}
                        ariaValuemax={resultData?.maxRange}
                        customLabel={resultData?.severity}
                        isLabelVisible={false}
                      />
                      <Box className={styles.progressbarScale}>
                        <h4>Little / none</h4>
                        <h4>Mild</h4>
                        <h4>Moderate</h4>
                        <h4>High</h4>
                        <h4>High to severe</h4>
                      </Box>
                    </div>
                    <div className={styles.progressBarDescription}>
                      <p dangerouslySetInnerHTML={{ __html: resultData.statement.replace(resultData.severity, `<strong>${resultData.severity}</strong>`) }} />
                    </div>
                  </div>
                </div>
              )}
            </form>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </CustomContainer>
  );
};

export default BehaviouralHealthDetails;
