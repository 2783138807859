import React, { ChangeEvent, FC } from 'react'

interface Props {
    title: string,
    FileIcon?: FC<React.SVGProps<SVGSVGElement>>
    colorBorder?: boolean,
    handleFileChange?: (name: string, file: File | null) => void;
    name?: string,
    onlyImg?: boolean,
    disable?: boolean,
    fillBg?: boolean,
    id: string
}
const InputFile: FC<Props> = ({
    title,
    FileIcon,
    colorBorder,
    handleFileChange,
    name = '',
    onlyImg,
    disable,
    fillBg,
    id=''
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault(); 
        const file = e.target.files?.[0] ?? null;
        if (handleFileChange) {
            handleFileChange(name, file);
        }
    };
    return (
        <div className="customFileInputMain">
            <input
                type="file"
                name={name}
                id={id}
                accept={onlyImg ? '.jpeg,.jpg,.png' : '.jpeg,.jpg,.png,.txt,.xlsx,.xls,.csv,.pdf,.doc,.docx'}
                onChange={handleChange}
                multiple={false} 
                className="customFileInput"
            />
            <label className={`cursor-pointer customFileInputLabel ${colorBorder ? 'colorBorder' : ''} ${fillBg ? 'fillBackground' : ''}`} htmlFor={id}>
                {FileIcon && <span><FileIcon /></span>}  {title}
            </label>
        </div>
    )
}

export default InputFile