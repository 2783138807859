import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { useLocation, useNavigate } from 'react-router-dom'
import { BackIcon, EmotionalAwarenessIcon, GeneralHealthIcon, InactualAwarenessIcon, MentalAwarenessIcon, NutritionIcon, OccupationalAwarenessIcon, PhysicalActivityIcon, SafetyIcon, SAndEAwarenessIcon } from '../../utils/functions/Icons';
import styles from "../../assets/styles/assessments.module.css"
import UserScore from '../../components/common/UserScore';
import HraCard from '../../components/common/HraCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/store';
import { toast } from 'react-toastify';
import { getAssessmentResultApi } from '../../utils/api/services';

const HraResult = () => {
    const [patientDetails, setPatientDetails] = useState<PatientDetails>();
    const [loading, setLoading] = useState(false)
    const [resultData, setResultData] = useState<{} | any>({})
    const navigate = useNavigate();
    const location = useLocation()
    const { LoginData } = useSelector((state: RootState) => state.auth)

    const fetchAssessmentResult = async (id: number) => {
        try {
            setLoading(true)
            const result = await getAssessmentResultApi(id);
            if (result?.success) {
                setLoading(false)
                setResultData(result?.assessments && result?.assessments[0]?.assessmentTaken[0]?.response)
            } else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (location?.state?.id && location?.state?.isResult) {
            fetchAssessmentResult(location?.state?.id);
        }
        if (location.state?.resultData) {
            setResultData(location.state?.resultData)
        }
        if (LoginData) {
            setPatientDetails({
                name: `${LoginData?.first_name} ${LoginData?.last_name ? LoginData?.last_name : ''}`,
                patientId: Number(LoginData?.uniquePatientId),
                profilePic: LoginData?.profilePic,
                age: LoginData?.age,
                dateofbirth: LoginData?.dob,
                gender: LoginData?.gender,
                location: `${LoginData?.state} ${LoginData?.city ? `, ${LoginData?.city}` : ''}`,
                bloodGroup: LoginData?.bloodgroup,
            })
        }
    }, [location.pathname])

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4'><BackIcon onClick={() => navigate(-1)} />Results - HRA</h2>
                </div>
                <div className={styles.HraResultMain}>
                    <UserScore patientDetails={patientDetails} />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 md:gap-6 gap-y-6'>
                    {
                        Object.entries(resultData)?.map(([key, value]) => {
                            const { maxRange, percentage, result } = value as {
                                maxRange: number;
                                percentage: number;
                                result: string;
                            };
                            const item = {
                                maxRange,
                                percentage,
                                result,
                                title: key,
                                imgUrl: key === 'Physical Activity' ? <PhysicalActivityIcon /> : key === 'General Health' ? <GeneralHealthIcon /> : key === 'Nutrition' ? <NutritionIcon /> : key === 'Safety' ? <SafetyIcon /> : key === 'Social and Environmental Wellness' ? <SAndEAwarenessIcon /> : key === 'Emotional Awareness' ? <EmotionalAwarenessIcon /> : key === 'Mental Wellness' ? <MentalAwarenessIcon /> : key === 'Intellectual Wellness' ? <InactualAwarenessIcon /> : key === 'Occupational Wellness' ? <OccupationalAwarenessIcon /> : key === 'Values, Spirituality, and Beliefs' ? <PhysicalActivityIcon /> : '',
                                color: key === 'Physical Activity' ? '#0562CC' : key === 'General Health' ? '#B3261E' : key === 'Nutrition' ? '#05CC6C' : key === 'Safety' ? '#D66B1D' : key === 'Social and Environmental Wellness' ? '#0562CC' : key === 'Emotional Awareness' ? '#AA1515' : key === 'Mental Wellness' ? '#05CC6C' : key === 'Intellectual Wellness' ? '#85D61D' : key === 'Occupational Wellness' ? '#D66B1D' : key === 'Values, Spirituality, and Beliefs' ? '#0562CC' : '#0562CC'
                            }
                            return (
                                <HraCard item={item} key={key} />
                            )
                        })
                    }
                </div>
            </div>
        </CustomContainer>
    )
}

export default HraResult