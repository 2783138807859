import yogaImg from "../../assets/images/common/yoga.png";
import avtarIcon from "../../assets/images/common/avtar.png";
import filesIcon from "../../assets/images/common/fileicon.png";
import pdfIcon from "../../assets/images/common/pdficon.png";
import userImg from "../../assets/images/common/usericon.png";
import QrCodeImg from "../../assets/images/common/qrcode.png";
import noDataImg from "../../assets/images/common/no-appointments.png";
import logo from "../../assets/images/layout/sidebarlogo.png";
import assessmentBanner from "../../assets/images/banner/Assessment-Banner.png"
import BehavioralBanner from "../../assets/images/banner/Behavioral-Health-Banner.png"
import CareTeamBanner from "../../assets/images/banner/Care-Team-Banner.png"
import LabtestBanner from "../../assets/images/banner/Lab-Test-Banner.png"
import MedicineBanner from "../../assets/images/banner/Medicine-Banner.png"
export const Images = {
  yogaImg,
  avtarIcon,
  filesIcon,
  noDataImg,
  logo,
  userImg,
  QrCodeImg,
  pdfIcon,
  assessmentBanner,
  BehavioralBanner,
  CareTeamBanner,
  LabtestBanner,
  MedicineBanner
};
