import React, { FC } from 'react'
import styles from "../../assets/styles/behaviouralhealth.module.css";
interface Props {
    item: Assessment,
    handleStartNow: (item: Assessment) => void
}
const Card: FC<Props> = ({ item, handleStartNow }) => {
    return (
        <div className={styles.cardMain}>
            <h3>{item?.assessmentName}</h3>
            <img src={item?.assessmentImage} alt="yoga image" />
            <button className='outLineBtn w-full' onClick={() => handleStartNow(item)} >Start Now</button>
        </div>
    )
}

export default Card