import { useState, useRef, useCallback } from 'react';
import io, { Socket } from 'socket.io-client';

const SOCKET_SERVER_URL = 'https://patientportalapi.akosmd.in';

interface UseSocket {
  isConnected: boolean;
  connect: () => void;
  disconnect: () => void;
  sendMessage: (eventName: string, data: any) => Promise<any>;
  listenForEvent: (eventName: string, callback: (data: any) => void) => void;
}

const useSocket = (): UseSocket => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const socketRef = useRef<Socket | null>(null);

  const connect = useCallback(() => {
    if (!socketRef.current) {
      const socket = io(SOCKET_SERVER_URL, {
        transports: ['websocket'],
        secure: true,
        reconnection: true,
        autoConnect: true,
      });

      socketRef.current = socket;

      socket.on('connect', () => {
        setIsConnected(true);
        console.log('Connected to socket server');
      });

      socket.on('disconnect', () => {
        setIsConnected(false);
        console.log('Disconnected from socket server');
      });
    }
  }, []);

  const disconnect = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      setIsConnected(false);
      console.log('Socket manually disconnected');
    }
  }, []);

  const sendMessage = (eventName: string, data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (socketRef.current) {
        socketRef.current.emit(eventName, data, (response: any) => {
          if (response?.success) {
            resolve(response);
          } else {
            reject(new Error('Failed to send message'));
          }
        });
      } else {
        reject(new Error('Socket not connected'));
      }
    });
  };

  const listenForEvent = (eventName: string, callback: (data: any) => void) => {
    if (socketRef.current) {
      socketRef.current.on(eventName, callback);
    }
  };

  return {
    isConnected,
    connect,
    disconnect,
    sendMessage,
    listenForEvent,
  };
};

export default useSocket;
