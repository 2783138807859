import React from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/rpmmanagement.module.css"
import RPMManagementTable from '../../components/rpmmanagement/RPMManagementTable'

const RpmAlertManagement = () => {
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>RPM Alert Management</h2>
                </div>
                <div className={styles.rpmManagementMain}>
                    {/* <PatientDataCard /> */}
                    <div className='mt-6'>
                        <RPMManagementTable  />
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default RpmAlertManagement