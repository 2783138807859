import styles from "../../assets/styles/layout.module.css"
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import SidebardHeader from './SidebardHeader'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { handleToggle } from "../../utils/store/common/CommonSlice"
import { useEffect } from "react"
const Layout = () => {
    const { sidebarToggle } = useSelector((state: RootState) => state.common)
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                dispatch(handleToggle(true));
            } else {
                dispatch(handleToggle(false));
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [dispatch, sidebarToggle]);

    useEffect(() => {
        if (window.innerWidth < 768) {
            dispatch(handleToggle(true));
        } 
    }, [])
    return (
        <div className={styles.dashboardLayout}>
            <Sidebar />
            <div className={`${styles.pageContainer} ${sidebarToggle ? styles.toggle : ''}`}>
                <SidebardHeader />
                <div className={styles.dashboard_mainRapper}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout