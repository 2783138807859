import React, { FC } from 'react'
import styles from "../../assets/styles/layout.module.css"
import { CrossIcon } from '../../utils/functions/Icons'

interface Props {
    item: NotificationHeader;
    removeNotification: (data: NotificationHeader) => void
}
const NotificationCard: FC<Props> = ({ item, removeNotification }) => {
    return (
        <>
            {
                item?.isActive !== 0 &&
                <>
                    <div className={styles.notificationContentMain}>
                        <div className={styles.NContentSec}>
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                        </div>
                        <div className={styles.NDeleteSec} onClick={() => removeNotification(item)}>
                            <CrossIcon />
                        </div>
                    </div>
                    <hr className="hrLine" />
                </>
            }
        </>
    )
}

export default NotificationCard