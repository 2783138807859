import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CrossIcon, PhoneSimpleIcon, WatchIcon } from '../../utils/functions/Icons'
import styles from "../../assets/styles/appointments.module.css"
import { DateCalendar } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import BtnLoader from '../common/BtnLoader'
import { bookAppointmentApi, getSlotsAvailibity, rescheduleAppointmentApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Images } from '../../utils/functions/Images'
import { fetchCancelledAppReducer } from '../../utils/store/appointment/AppointmentSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
interface SlotsTypes {
    id: number;
    startTime: string;
    endTime: string;
    isActive: boolean
};
interface Availability {
    dayOfWeek: string;
    isHoliday: boolean;
    slots: SlotsTypes[];
}
interface propTypes {
    isModalOpen: boolean,
    handleCloseModal: () => void,
    particularData?: CareTeamTypes | null,
    confirmBooking?: (data: CareTeamTypes, date: string, time: string) => void,
}
const BookAppointmentModal: React.FC<propTypes> = ({
    isModalOpen,
    handleCloseModal,
    particularData,
}) => {
    const [date, setDate] = useState<string | null>(null)
    const [selectedTime, setSelectedTime] = useState<string | null>(null)
    const [btnLoading, setBtnLoading] = useState(false);
    const [availableSlots, setAvailableSlots] = useState<SlotsTypes[] | []>([]);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const handleDateChange = async (newDate: Moment | null) => {
        setSelectedTime('')
        const formattedDate = newDate ? newDate.format('YYYY-MM-DD') : null; 
        setDate(formattedDate);
        if (formattedDate && (particularData?.id || particularData?.doctorId)) {
            const result = await getSlotsAvailibity(particularData?.doctorId ? particularData?.doctorId : particularData.id);
            if (result.success) {
                const dayOfWeek = moment(newDate).format('dddd');

                const dayAvailability = result.availability.find((availability: Availability) =>
                    availability.dayOfWeek === dayOfWeek && !availability.isHoliday
                );

                if (dayAvailability) {
                    setAvailableSlots(dayAvailability.slots);
                } else {
                    setAvailableSlots([]);
                }
            } else {
                setAvailableSlots([]);
            }
        }
    };

    const handleTimeSelection = (time: string) => {
        setSelectedTime(time)
    }

    const handleConfirmBooking = async () => {
        try {
            if (particularData?.reschedule) {
                if (particularData && date && selectedTime) {
                    const result = await rescheduleAppointmentApi({
                        appointmentId: particularData?.appointmentId,
                        date: date,
                        time: selectedTime
                    });
                    if (result?.success) {
                        setBtnLoading(false)
                        toast.success(result?.message)
                        handleCloseModal()
                        dispatch(fetchCancelledAppReducer({ search: particularData?.searchValue || '', id: 3 }));
                    } else {
                        setBtnLoading(false)
                        toast.error(result?.message)
                    }
                }
            }
            else {
                if (particularData && date && selectedTime) {
                    const result = await bookAppointmentApi({
                        patientId: LoginData?.loggedPatientId,
                        doctorId: particularData?.id,
                        date: date,
                        time: selectedTime
                    });
                    if (result?.success) {
                        setBtnLoading(false)
                        toast.success(result?.message)
                        handleCloseModal()
                        navigate('/MyAppointments/book/thanks')
                    } else {
                        setBtnLoading(false)
                        toast.error(result?.message)
                    }
                }
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }


    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
        >
            <Box sx={{ width: 800 }} className={"ModalBoxStyle"}>
                <div className="modalHeader justifyEnd">
                    <CrossIcon onClick={handleCloseModal} />
                </div>
                <div className='ModalContentSection paddingTop0 ResponsiveContentModal'>
                    <div className={styles.BookAppointmentModal}>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6 gap-y-6'>
                            <section className={`${styles.BAppointLeft} flex flex-col gap-y-3 justify-between`}>
                                <div className={styles.BADoctorData}>
                                    <div className={styles.BADdocImg}>
                                        <img src={particularData?.profilePic ? particularData?.profilePic : Images.avtarIcon} alt={particularData?.name} />
                                    </div>
                                    <h4>{particularData?.name}</h4>
                                    <h5>{particularData?.speciality}</h5>
                                    <p>{particularData?.experience} years of experience</p>
                                    <div className={styles.BADWatch}>
                                        <WatchIcon />
                                        <p>30 min</p>
                                    </div>
                                    <div className={styles.BADWatch}>
                                        <PhoneSimpleIcon />
                                        <p>Video Consultation</p>
                                    </div>

                                </div>
                                <div className=''>
                                    <button
                                        onClick={handleConfirmBooking}
                                        className='fillButton'
                                        disabled={btnLoading || !date || !selectedTime}
                                    >
                                        {btnLoading && <BtnLoader />}
                                        Confirm Booking
                                    </button>
                                </div>
                            </section>
                            <section className={styles.BAppointRight}>
                                <div className={styles.BCalenderHead}>
                                    <h3>Select a Date & Time</h3>
                                </div>
                                <div className='CustomcalenderWrapper'>
                                    <DateCalendar
                                        minDate={moment(new Date())}
                                        className='customDateCalendar'
                                        value={date ? moment(date, 'YYYY-MM-DD') : null}
                                        onChange={(newDate) => handleDateChange(newDate)}
                                    />
                                </div>
                                <div className={styles.availableAppointment}>
                                    <div className="grid grid-cols-3 gap-4 pt-4">
                                        {
                                            availableSlots?.length > 0 ?
                                                <>
                                                    {availableSlots?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            {
                                                                item?.isActive ?
                                                                    <div
                                                                        key={index}
                                                                        className={`${styles.availableApBox} ${selectedTime === item?.startTime ? styles.selectedTime : ''}`}
                                                                        onClick={() => handleTimeSelection(item?.startTime)}
                                                                    >
                                                                        <p>{item?.startTime}</p>
                                                                    </div>
                                                                    :
                                                                    <div className={`col-span-3 ${styles.noSlots}`}>
                                                                        <p>No slots available for selected date</p>
                                                                    </div>

                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                                :
                                                <div className={`col-span-3 ${styles.noSlots}`}>
                                                    <p>No slots available for selected date</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default BookAppointmentModal
