import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, TextField } from "@mui/material";
import { useState } from "react";
import { SearchIcon } from "../../utils/functions/Icons";

interface AutocompleteInputProps<T> {
    options: (string | { [key: string]: T })[];
    multiple: boolean;
    className: string;
    value?: (string | { [key: string]: T }) | (string | { [key: string]: T })[]
    getOptionLabel: (option: string | { [key: string]: T }) => string;
    getOptionKey: (option: string | { [key: string]: T }) => string;
    createOption: (inputValue: string) => { [key: string]: T };
    onSelect: (value: string | { [key: string]: T } | (string | { [key: string]: T })[] | null) => void;
    [key: string]: any;
  }
  
  const AutocompleteInput: React.FC<AutocompleteInputProps<any>> = ({
    options,
    multiple,
    className,
    getOptionLabel,
    getOptionKey,
    createOption,
    onSelect,
    value,
    ...props
  }) => {
    const [inputValue, setInputValue] = useState('');
  
    const handleChange = (event: React.SyntheticEvent, value: string | { [key: string]: any; } | (string | { [key: string]: any; })[] | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string | { [key: string]: any; }> | undefined) => {
        if (typeof value === 'string') {
          const newOption = createOption(value);
          options.push(newOption);
          setInputValue('');
        } else {
          onSelect(value);
        }
      };
  
    return (
      <Autocomplete
        freeSolo
        options={options}
        multiple={multiple}
        className={className}
        value={value || ''}
        getOptionLabel={getOptionLabel}
        getOptionKey={getOptionKey}
        filterOptions={(options, { inputValue }) => {
            return options.filter((option) => {
              const values = Object.values(option);
              return values.some((value) => {
                return typeof value === 'string' && value.toLowerCase().includes(inputValue.toLowerCase());
              });
            });
          }}
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <div className="flex items-center">
            <TextField {...params} className="autocompleteInputStyle" />
            <span className="searchIconOnAutocomplete">
              <SearchIcon />
            </span>
          </div>
        )}
      />
    );
  };
  
  export default AutocompleteInput;