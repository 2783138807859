import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMedicineApi } from "../../api/services";

interface MyMedicineTypes {
  AllMedicineData: medicineTypes[];
  loading: boolean;
}

export const MyMedicineApiReducer = createAsyncThunk<
  MyMedicineTypes["AllMedicineData"],
  { search: string; timeFilter: string | number }
>("mymedicine/MyMedicineApiReducer", async (data, thunkAPI) => {
  try {
    const result = await getMedicineApi(data);
    if (result?.success) {
      return result?.formattedMedicines as MyMedicineTypes["AllMedicineData"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: MyMedicineTypes = {
  AllMedicineData: [],
  loading: false,
};

const MyMedicineSlice = createSlice({
  name: "mymedicine",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(MyMedicineApiReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      MyMedicineApiReducer.fulfilled,
      (
        state,
        action: PayloadAction<MyMedicineTypes["AllMedicineData"] | undefined>
      ) => {
        state.AllMedicineData = action.payload ?? [];
        state.loading = false;
      }
    );
    builder.addCase(MyMedicineApiReducer.rejected, (state) => {
      state.loading = false;
      state.AllMedicineData = [];
    });
  },
});

export default MyMedicineSlice;
