import React, { FC } from 'react'
import styles from "../../assets/styles/medicalrecord.module.css"
import userImg from "../../assets/images/team/1.png"
import { Images } from '../../utils/functions/Images'

interface Props {
    profilePic: string | null,
    name: string | null,
    patientId: number,
    age: number | null,
    dateofbirth: string,
    gender: string,
    location: string | null,
    bloodGroup: string | null,
}
const PatientDataCard: FC<Props> = ({
    profilePic,
    name,
    patientId,
    age,
    dateofbirth,
    gender,
    location,
    bloodGroup,
}) => {
    return (
        <section className={styles.medicalRecordUserSec}>
            <div className={styles.userNameAndImg}>
                <div className={styles.userImg}>
                    <img src={profilePic ? profilePic : Images.avtarIcon} alt="user image" />
                </div>
                <div className={styles.usercontent}>
                    <h3>{name}</h3>
                    <p>Patient ID: {patientId}</p>
                </div>
            </div>
            {
                age &&
                <div className={`${styles.userOtherData} ${styles.fixMinWidth}`}>
                    <h4>Age</h4>
                    <p>{age}</p>
                </div>
            }
            {
                dateofbirth &&
                <div className={styles.userOtherData}>
                    <h4>Date of birth</h4>
                    <p>{dateofbirth}</p>
                </div>
            }
            {
                gender &&
                <div className={styles.userOtherData}>
                    <h4>Gender</h4>
                    <p>{gender}</p>
                </div>
            }
            {
                location &&
                <div className={styles.userOtherData}>
                    <h4>Location</h4>
                    <p>{location}</p>
                </div>
            }
            {/* <div className={styles.userOtherData}>
                <h4>Height</h4>
                <p>165 cm</p>
            </div>
            <div className={styles.userOtherData}>
                <h4>Weight</h4>
                <p>80kg</p>
            </div> */}
            {
                bloodGroup &&
                <div className={styles.userOtherData}>
                    <h4>Blood Group</h4>
                    <p>{bloodGroup}</p>
                </div>
            }
        </section>
    )
}

export default PatientDataCard