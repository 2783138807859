import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
    message: string
}
const WaitingStatusCom: FC<Props> = ({ message }) => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        // navigate(-1)
        window.close()
    }
    return (
        <div className='waitingStatusMain'>
            <h3>{message}</h3>
            <button className='fillButton' onClick={handleGoBack}>GO Back</button>
        </div>
    )
}

export default WaitingStatusCom