import { FC } from "react"
import { Images } from "../../utils/functions/Images"

interface Props {
    imgUrl?: string,
    title?: string
}
const NoDataFound: FC<Props> = ({ imgUrl, title }) => {
    return (
        <div className='flex items-center justify-center flex-col w-full'>
            <img src={imgUrl ? imgUrl : Images.noDataImg} alt="No Appointment" />
            {
                title &&
                <h3>{title}</h3>
            }
        </div>
    )
}

export default NoDataFound